import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";

export const useDashBoardStore = defineStore("dashboard", {
    state: () => ({
        dashboard: {},
        pnl: [],
        stocksAll: [],
        stocks: [],
        ordersAll: [],
        orders: [],
        categories: [],
    }),

    actions: {
        async getDashBoard() {
            console.log("getDashBoard");

            mainStore().request(true);

            try {
                const res = await API.GET("api/dashboard");
                if (res.data.alert) mainStore().setAlert(res.data.alert);
                console.log("dashboard", res.data.dashboard);

                this.dashboard = res.data.dashboard;
                this.stocksAll = res.data.stocks;
                this.stocks = res.data.stocks;
                this.ordersAll = res.data.orders;
                this.orders = res.data.orders;
                this.categories = res.data.categories;
            } catch (error) {
                console.error(error);
            } finally {
                mainStore().request(false);
            }
        },

        async getPnl() {
            const res = await API.GET("api/pnl");
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            this.pnl = res.data.pnl;
        },

        // fillOrders() {
        //     this.setOrdersF(this.state.orders);
        // },

        // filterOrders() {
        //     if (!this.orders) return null;

        //     let newList = this.orders;

        //     if (s.findme && s.findme !== undefined) {
        //         newList = newList.filter((item) => {
        //             let offer_id = null;
        //             let warehouse = null;

        //             if (item.offer_id) offer_id = item.offer_id.toString().toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
        //             if (item.warehouse) warehouse = item.warehouse.toString().toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
        //             if (offer_id || warehouse) return true;

        //             return false;
        //         });
        //     }

        //     if (s.start && s.start !== undefined) {
        //         newList = newList.filter((item) => item.created.substring(0, 10) >= s.start);
        //     }

        //     if (s.end && this.rootState.s.end !== undefined) {
        //         newList = newList.filter((item) => item.created.substring(0, 10) <= this.rootState.s.end);
        //     }

        //     if (this.rootState.s.status && this.rootState.s.status !== undefined) {
        //         newList = newList.filter((item) => parseInt(item.status) === parseInt(this.rootState.s.status));
        //     }

        //     if (this.rootState.s && newList.length !== this.state.orders.length) {
        //         this.rootState.s.clear = true;
        //     }

        //     this.setSettings(this.rootState.s);
        //     this.setOrdersF(newList);
        // },

        filterStocks() {
            if (!this.stocksAll || !mainStore().s) return null;

            let newList = this.stocksAll;
            let s = mainStore().s;

            if (s.findme && s.findme !== undefined) {
                newList = newList.filter((item) => {
                    let offer_id = null;
                    let name = null;

                    if (item.offer_id) offer_id = item.offer_id.toString().toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.name) name = item.name.toString().toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    if (offer_id || name) return true;

                    return false;
                });
            }

            if (s.category_id && s.category_id !== undefined) {
                newList = newList.filter((item) => parseInt(item.category_id) === parseInt(s.category_id));
            }

            if (newList.length !== this.stocks.length) {
                s.clear = true;
            }

            mainStore().setSettings = s;
            this.stocks = newList;
        },
    },
});
