import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";

export const useFinanceStore = defineStore("finance", {
    state: () => ({
        dailyAll: [], // расходы и доходы по дням
        daily: [], // расходы и доходы по дням
    }),

    actions: {
        async getDailyFinance() {
            const res = await API.GET("finance/expense/daily");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.dailyAll = res.data.daily;
            this.daily = res.data.daily;
        },
    },
});
