import { defineStore } from "pinia";
import moment from "moment";

export const useMainStore = defineStore("main", {
    state: () => ({
        alert: {},
        s: {
            findme: null,
            category_id: null,
            status: null,
            month: moment().startOf("month").format("YYYY-MM-DD"),
            start: null,
            end: null,
            popup: null,
            clear: null,
            view: null,
            activeMenu: null,
        },

        month: ["", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        monthMin: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
        monthShort: { 1: "Янв", 2: "Фев", 3: "Мар", 4: "Апр", 5: "Май", 6: "Июн", 7: "Июл", 8: "Авг", 9: "Сен", 10: "Окт", 11: "Ноя", 12: "Дек" },
        weekday: ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        routes: [],
    }),

    actions: {
        // загрузить данные из localStorage
        loadData() {
            const s = localStorage.getItem("s");
            if (s && s !== "undefined") this.s = JSON.parse(s);
            this.s.popup = null;
        },

        setAlert(alert) {
            this.alert = alert;
        },

        setSettings(s) {
            this.s = s;
            localStorage.setItem("s", JSON.stringify(s));
        },

        clearSettings() {
            this.s.findme = null;
            this.s.category_id = null;
            this.s.status = null;
            this.s.month = moment().startOf("month").format("YYYY-MM-DD");
            this.s.start = null;
            this.s.end = null;
            this.s.clear = null;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        request(ctx, status) {
            this.s.request = status;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setPopup(popup) {
            this.s.popup = popup;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        closePopup() {
            this.s.popup = false;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setActiveMemu(active) {
            this.s.activeMenu = active;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setView(view) {
            this.s.view = view;
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setMonth(month) {
            let date = moment().format("YYYY-") + month + "-01";
            this.s.month = month;
            this.s.start = moment(date).format("YYYY-MM-01");
            this.s.end = moment(date).endOf("month").format("YYYY-MM-DD");
            localStorage.setItem("s", JSON.stringify(this.s));
        },

        setPeriod(data) {
            this.s.month = null;
            this.s.start = data.start;
            this.s.end = data.end;
            localStorage.setItem("s", JSON.stringify(this.s));
        },
    },
});
