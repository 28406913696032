<template>
    <div class="flex min-h-full gap-4 p-4">
        <div class="box gradient w-1/2 rounded max-l:mx-auto">
            <div class="flex text-base text-slate-400 flex-col items-center">
                <div class="w-48 h-48 my-5 mx-auto rounded-full bg-slate-200 overflow-hidden relative">
                    <span class="w-full h-full group">
                        <input type="file" id="avatar" class="absolute invisible opacity-0" multiple @change="uploadAvatar({ e: $event })" />
                        <label
                            for="avatar"
                            class="w-1/2 h-1/2 flex items-center justify-center rounded-full absolute -top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 group-hover:bg-black group-hover:opacity-50 group-hover:top-1/2"
                        >
                            <svg class="fill-white w-5 h-5"><use xlink:href="@/assets/i.svg#ico-edit"></use></svg>
                        </label>

                        <img v-if="profile?.picture" class="w-full h-full object-cover" :src="'https://api.ulangroup.ru/users/' + profile?.picture" />
                        <img v-else class="w-full h-full object-cover" src="https://yii.botteach.ru/users/mini/u0.png" />
                    </span>
                </div>

                {{ profile?.name }} {{ profile?.secondname }}
            </div>
            <div class="flex">
                <!-- <a class="mx-auto mt-4 v-btn bg-sky-500 hover:bg-sky-400 transition-colors duration-150" @click="editProfile">
                        <svg class="fill-white w-16px h-16px mr-3">
                            <use xlink:href="@/assets/i.svg#ico-edit"></use>
                        </svg>
                        <span class="v-btn_text">РЕДАКТИРОВАТЬ</span>
                    </a> -->
                <a
                    class="mx-auto mt-4 v-btn py-2 px-4 cursor-pointer rounded bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
                    @click="logout()"
                >
                    <span class="text-white">ВЫХОД</span>
                </a>
            </div>
        </div>
        <div class="gradient w-1/2 rounded max-l:mx-auto">
            <h2 class="p-4 text-center text-slate-400 border-b border-slate-200">РОЛИ</h2>

            <div class="p-4">
                <template v-for="r in profile?.roles" :key="r">
                    <div v-if="profile.r == r" class="flex gap-4 mb-2 text-sky-500 hover:text-sky-400 cursor-pointer">
                        <IconOk /> {{ roleNames[r] }}
                    </div>
                    <div v-else class="flex gap-4 mb-2 text-slate-500 hover:text-sky-400 cursor-pointer" @click="changeRole(r)">
                        <IconRound /> {{ roleNames[r] }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useProfileStore } from "@/store/profile";

export default {
    name: "ProfilePage",

    data() {
        return {
            roleNames: { An: "Админ", Or: "Владелец", Mn: "Менеджер" },
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useProfileStore, ["profile", "getProfile", "logout", "uploadAvatar"]),
    },

    mounted() {
        this.getProfile();
    },
};
</script>
