<template>
    <template v-if="buying && buying.id">
        <div class="flex justify-between items-center gap-4 mt-4 mb-2">
            <h2 class="flex items-center gap-2 text-slate-500 text-2xl uppercase">Закупка #{{ buying.id }}</h2>
        </div>

        <div class="flex w-full gap-4">
            <div class="w-1/6 flex flex-col mb-4">
                <div class="w-full flex flex-col mb-4">
                    <label class="text-xs text-left text-slate-400 mb-1">Поставщик</label>
                    <select class="input" @change="setSupplier(buying.supplier_id)" v-model="buying.supplier_id">
                        <option value="">Поставщик</option>
                        <template v-for="supplier in suppliers" :key="supplier">
                            <option :value="supplier.id" :selected="buying.supplier_id == supplier.id">{{ supplier.name }}</option>
                        </template>
                    </select>
                </div>
            </div>

            <div class="w-1/6 flex flex-col mb-4">
                <label class="text-xs text-left text-slate-400 mb-1">Трек номер</label>
                <input type="text" class="input" @change="saveBuying(buying)" v-model="buying.track" />
            </div>

            <div class="w-1/6 flex flex-col mb-4">
                <label class="text-xs text-left text-slate-400 mb-1">Суммарная стоимость</label>
                <input type="text" class="input" @change="saveBuying(buying)" v-model="buying.cost" />
            </div>
            <div class="w-1/6 flex flex-col mb-4">
                <label class="text-xs text-left text-slate-400 mb-1">Стоимость доставки</label>
                <input type="text" class="input" @change="saveBuying(buying)" v-model="buying.delivery" />
            </div>
            <div class="w-1/6 flex flex-col mb-4">
                <label class="text-xs text-left text-slate-400 mb-1">Вес</label>
                <input type="text" class="input" @change="saveBuying(buying)" v-model="buying.weight" />
            </div>

            <div class="w-1/6 flex flex-col mb-4">
                <label class="text-xs text-left text-slate-400 mb-1">Плановая дата приемки</label>
                <input type="datetime-local" class="input" @change="saveBuying(buying)" v-model="buying.planned" />
            </div>
        </div>

        <div v-if="buying.buyingProducts">
            <div class="flex items-center gap-2">
                <p class="text-xl text-slate-400 my-4">Товары</p>
                <span class="btn btn-green w-fit" @click="setPopup('addProductToBuying')">добавить</span>
            </div>

            <div class="grid grid-cols-12 gap-4 py-1 px-2 w-full text-white/50 text-xs uppercase">
                <div>Товар</div>
                <div class="col-span-2">ОФФЕР</div>
                <div class="col-span-5">НАИМЕНОВАНИЕ</div>
                <div>ЦЕНА ШТ.</div>
                <div>КОЛ-ВО</div>
                <div class="text-right">СУММА</div>
                <div></div>
            </div>

            <div
                v-for="bp in buying.buyingProducts"
                :key="bp"
                class="grid grid-cols-12 items-center gap-4 text-slate-400 border-b border-white/10 p-2 text-sm"
            >
                <img class="w-12 h-12 rounded shrink-0" :src="bp.product.image" :alt="bp.product.name" />
                <div class="col-span-2">{{ bp.product.offer_id }}</div>
                <div class="col-span-5">{{ bp.product.name }}</div>
                <div><input class="input" @change="saveProductInBuying(bp)" type="text" v-model="bp.price" /></div>
                <div><input class="input" @change="saveProductInBuying(bp)" type="text" v-model="bp.qty" /></div>
                <div class="text-right">{{ (bp.price * bp.qty).toLocaleString() }}</div>
                <div class="flex justify-end cursor-pointer" @click="removeProduct(bp.product_id)"><IconMinus :size="5" /></div>
            </div>
        </div>
        <div v-else class="flex items-center gap-2">
            <p class="text-xl text-slate-400 my-4">Товары еще не добавлены</p>
            <span class="btn btn-green w-fit" @click="setPopup('addProductToBuying')">добавить</span>
        </div>

        <div class="flex justify-between gap-4 mt-4">
            <template v-if="buying.status == 1">
                <div @click="delBuying(moment())" class="btn btn-red">УДАЛИТЬ</div>
                <div @click="statusBuying(2)" class="btn">ЗАПЛАНИРОВАТЬ</div>
            </template>

            <template v-if="buying.status == 2">
                <div></div>
                <div @click="statusBuying(3)" class="btn">ОПЛАТИТЬ</div>
            </template>

            <template v-if="buying.status == 3">
                <div></div>
                <div @click="statusBuying(4)" class="btn">ОТПРАВИТЬ</div>
            </template>

            <template v-if="buying.status == 4">
                <div></div>
                <div @click="statusBuying(5)" class="btn">ПОЛУЧИТЬ</div>
            </template>
        </div>

        <template v-if="s.popup == 'addProductToBuying' && buying.id"><AddProductToBuying /> </template>
    </template>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useSkladStore } from "@/store/sklad";

import moment from "moment";
import AddProductToBuying from "../components/Sklad/AddProductToBuying.vue";

export default {
    name: "BuyingPage",

    components: { AddProductToBuying },

    data() {
        return {
            moment: moment,
            statuses: {
                1: "Создана",
                2: "План",
                3: "Оплачена",
                4: "Отправлена",
                5: "Получена",
            },
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "setPopup", "closePopup", "setSettings", "setMonth", "setPeriod", "setView"]),
        ...mapState(useSkladStore, [
            "getSklad",
            "createBuying",
            "pickBuying",
            "setSupplier",
            "saveBuying",
            "delBuying",
            "statusBuying",
            "removeProduct",
            "saveProductInBuying",
            "buyings",
            "buying",
            "suppliers",
        ]),
    },

    watch: {
        buying: {
            handler() {
                this.calculateTotalCost();
            },
            deep: true,
        },
    },

    methods: {
        clear() {
            this.clearSettings();
            this.fillOrders();
        },

        calculateTotalCost() {
            let totalCost = 0;
            this.buying.buyingProducts.forEach((product) => {
                totalCost += product.price * product.qty;
            });
            this.buying.cost = totalCost;
        },
    },

    mounted() {
        this.getSklad();
    },
};
</script>
