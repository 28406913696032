import { defineStore } from "pinia";
import API from "../services/api";
import router from "@/services/router";

import { useMainStore as mainStore } from "./main";

export const useSkladStore = defineStore("sklad", {
    state: () => ({
        buyingsAll: [],
        buyings: [],
        buying: {},

        suppliers: [],

        forBuying: [],
    }),

    actions: {
        loadSklad() {
            const buying = localStorage.getItem("buying");
            if (buying) this.buying = JSON.parse(buying);
        },

        async getSklad() {
            const res = await API.GET("sklad/buying/all");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
            this.suppliers = res.data.suppliers;

            this.loadSklad();
        },

        pickBuying(buying) {
            this.buying = buying;
            localStorage.setItem("buying", JSON.stringify(buying));
        },

        async createBuying() {
            const res = await API.POST("sklad/buying/create");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;

            if (res.data.buying) {
                this.buying = res.data.buying;
                localStorage.setItem("buying", JSON.stringify(this.buying));
                router.push("/buying");
            }
        },

        async addProductToBuying(product_id) {
            if (!this.buying) return;

            let formData = new FormData();
            formData.append("id", parseInt(this.buying.id));
            formData.append("product_id", parseInt(product_id));

            const res = await API.POST("sklad/buying/add-product", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
            this.buying = res.data.buying;
            localStorage.setItem("buying", JSON.stringify(this.buying));
        },

        async removeProduct(product_id) {
            if (!this.buying) return;

            let formData = new FormData();
            formData.append("id", parseInt(this.buying.id));
            formData.append("product_id", parseInt(product_id));

            const res = await API.POST("sklad/buying/remove-product", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
            this.buying = res.data.buying;
            localStorage.setItem("buying", JSON.stringify(this.buying));
        },

        async saveProductInBuying(bp) {
            if (!this.buying) return;

            let formData = new FormData();
            formData.append("buying_id", parseInt(this.buying.id));
            formData.append("product_id", parseInt(bp.product_id));
            formData.append("price", bp.price);
            formData.append("qty", parseInt(bp.qty));

            const res = await API.POST("sklad/buying/save-product", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buying = res.data.buying;
            localStorage.setItem("buying", JSON.stringify(this.buying));
        },

        async setSupplier(supplier_id) {
            let formData = new FormData();
            formData.append("id", this.buying.id);
            formData.append("supplier_id", supplier_id);

            const res = await API.POST("sklad/buying/supplier", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
            this.buying = res.data.buying;
            localStorage.setItem("buying", JSON.stringify(this.buying));
        },

        async saveBuying(buying) {
            console.log("buying", buying);
            let formData = new FormData();
            formData.append("id", this.buying.id);
            formData.append("track", buying.track);
            formData.append("cost", buying.cost);
            formData.append("delivery", buying.delivery);
            formData.append("weight", buying.weight);
            formData.append("planned", buying.planned);

            const res = await API.POST("sklad/buying/save", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
            this.buying = res.data.buying;
            localStorage.setItem("buying", JSON.stringify(this.buying));
        },

        async statusBuying(status) {
            let formData = new FormData();
            formData.append("id", this.buying.id);
            formData.append("status", status);

            const res = await API.POST("sklad/buying/status", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
            this.buying = res.data.buying;
            localStorage.setItem("buying", JSON.stringify(this.buying));
        },

        async payBuying(date) {
            let formData = new FormData();
            formData.append("id", this.buying.id);
            formData.append("date", date);

            const res = await API.POST("sklad/buying/payed", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
            this.buying = res.data.buying;
            localStorage.setItem("buying", JSON.stringify(this.buying));
        },

        async sendBuying(date) {
            let formData = new FormData();
            formData.append("id", this.buying.id);
            formData.append("date", date);

            const res = await API.POST("sklad/buying/sended", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
            this.buying = res.data.buying;
            localStorage.setItem("buying", JSON.stringify(this.buying));
        },

        async arriveBuying(date) {
            let formData = new FormData();
            formData.append("id", this.buying.id);
            formData.append("date", date);

            const res = await API.POST("sklad/buying/arrived", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
            this.buying = res.data.buying;
            localStorage.setItem("buying", JSON.stringify(this.buying));
        },

        async delBuying() {
            let formData = new FormData();
            formData.append("id", this.buying.id);

            router.push("/buyings");
            this.buying = null;
            localStorage.removeItem("buying");

            const res = await API.POST("sklad/buying/del", formData);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.buyingsAll = res.data.buyings;
            this.buyings = res.data.buyings;
        },

        // fillOrders() {
        //     this.setOrdersF(this.state.orders);
        // },

        // filterOrders() {
        //     if (!this.orders) return null;

        //     let newList = this.orders;

        //     if (s.findme && s.findme !== undefined) {
        //         newList = newList.filter((item) => {
        //             let offer_id = null;
        //             let warehouse = null;

        //             if (item.offer_id) offer_id = item.offer_id.toString().toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
        //             if (item.warehouse) warehouse = item.warehouse.toString().toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
        //             if (offer_id || warehouse) return true;

        //             return false;
        //         });
        //     }

        //     if (s.start && s.start !== undefined) {
        //         newList = newList.filter((item) => item.created.substring(0, 10) >= s.start);
        //     }

        //     if (s.end && this.rootState.s.end !== undefined) {
        //         newList = newList.filter((item) => item.created.substring(0, 10) <= this.rootState.s.end);
        //     }

        //     if (this.rootState.s.status && this.rootState.s.status !== undefined) {
        //         newList = newList.filter((item) => parseInt(item.status) === parseInt(this.rootState.s.status));
        //     }

        //     if (this.rootState.s && newList.length !== this.state.orders.length) {
        //         this.rootState.s.clear = true;
        //     }

        //     this.setSettings(this.rootState.s);
        //     this.setOrdersF(newList);
        // },

        filterStocks() {
            if (!this.stocksAll || !mainStore().s) return null;

            let newList = this.stocksAll;
            let s = mainStore().s;

            if (s.findme && s.findme !== undefined) {
                newList = newList.filter((item) => {
                    let offer_id = null;
                    let name = null;

                    if (item.offer_id) offer_id = item.offer_id.toString().toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.name) name = item.name.toString().toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    if (offer_id || name) return true;

                    return false;
                });
            }

            if (s.category_id && s.category_id !== undefined) {
                newList = newList.filter((item) => parseInt(item.category_id) === parseInt(s.category_id));
            }

            if (newList.length !== this.stocks.length) {
                s.clear = true;
            }

            mainStore().setSettings = s;
            this.stocks = newList;
        },

        // addToBuying(product) {
        // this.forBuying.push(product);
        // },

        // removeFromBuying(product) {
        // this.forBuying = this.forBuying.filter((item) => item != product);
        // },
    },
});
