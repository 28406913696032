<template>
    <div class="container mx-auto px-10 pt-20">
        <h2 class="uppercase text-center text-2xl text-cyan-500 font-bold mb-4">ПЛАТФОРМА</h2>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "MainPage",

    computed: {
        ...mapGetters(["accounts"]),
    },

    methods: {
        ...mapActions(["getAccounts", "pickAccount"]),
    },

    mounted() {
        this.getAccounts();
    },
};
</script>
