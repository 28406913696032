import API from "../../services/api";

export default {
    state: {
        dashboard: {},
        stocks: [],
        stocksF: [],
        categories: [],
    },

    mutations: {
        setDashBoard(state, dashboard) {
            state.dashboard = dashboard;
        },
        setStocks(state, stocks) {
            state.stocks = stocks;
            state.stocksF = stocks;
        },
        setStocksF(state, stocks) {
            state.stocksF = stocks;
        },
        setCategories(state, categories) {
            state.categories = categories;
        },
    },

    actions: {
        getDashBoard(ctx) {
            ctx.dispatch("request", true);

            API.GET("api/dashboard").then((res) => {
                // console.log(res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setDashBoard", res.data.dashboard);
                ctx.commit("setStocks", res.data.stocks);
                ctx.commit("setCategories", res.data.categories);
                ctx.dispatch("request", false);
            });
        },

        getOrders_dj(ctx) {
            ctx.dispatch("request", true);
            API.GET("v1/orderlist/").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setOrders", res.data.orders);
                ctx.dispatch("request", false);
            });
        },

        fillOrders(ctx) {
            ctx.commit("setOrdersF", ctx.state.orders);
        },

        pickCluster(ctx, cluster) {
            ctx.commit("setCluster", cluster);
        },

        filterOrders(ctx) {
            if (!ctx.state.orders) return null;

            let newList = ctx.state.orders;

            if (ctx.rootState.s.findme != "" && ctx.rootState.s.findme != undefined) {
                newList = newList.filter((item) => {
                    let offer_id = null;
                    let warehouse = null;

                    if (item.offer_id) offer_id = item.offer_id.toString().toLowerCase().indexOf(ctx.rootState.s.findme.toLowerCase()) !== -1;
                    if (item.warehouse) warehouse = item.warehouse.toString().toLowerCase().indexOf(ctx.rootState.s.findme.toLowerCase()) !== -1;
                    if (offer_id || warehouse) return true;

                    return false;
                });
            }

            if (ctx.rootState.s.start != "" && ctx.rootState.s.start != undefined) {
                newList = newList.filter((item) => item.created.substring(0, 10) >= ctx.rootState.s.start);
            }

            if (ctx.rootState.s.end != "" && ctx.rootState.s.end != undefined) {
                newList = newList.filter((item) => item.created.substring(0, 10) <= ctx.rootState.s.end);
            }

            if (ctx.rootState.s.status != "" && ctx.rootState.s.status != undefined) {
                newList = newList.filter((item) => parseInt(item.status) == parseInt(ctx.rootState.s.status));
            }

            if (ctx.rootState.s && newList.length != ctx.state.orders.length) {
                ctx.rootState.s.clear = true;
            }

            ctx.dispatch("setSettings", ctx.rootState.s);
            ctx.commit("setOrdersF", newList);
        },

        filterStocks(ctx) {
            if (!ctx.state.stocks) return null;

            let newList = ctx.state.stocks;

            if (ctx.rootState.s.findme != "" && ctx.rootState.s.findme != undefined) {
                newList = newList.filter((item) => {
                    let offer_id = null;
                    let name = null;

                    if (item.offer_id) offer_id = item.offer_id.toString().toLowerCase().indexOf(ctx.rootState.s.findme.toLowerCase()) !== -1;
                    if (item.name) name = item.name.toString().toLowerCase().indexOf(ctx.rootState.s.findme.toLowerCase()) !== -1;
                    if (offer_id || name) return true;

                    return false;
                });
            }

            if (ctx.rootState.s.category_id != "" && ctx.rootState.s.category_id != undefined) {
                newList = newList.filter((item) => parseInt(item.category_id) == parseInt(ctx.rootState.s.category_id));
            }

            if (ctx.rootState.s && newList.length != ctx.state.stocks.length) {
                ctx.rootState.s.clear = true;
            }

            ctx.dispatch("setSettings", ctx.rootState.s);
            ctx.commit("setStocksF", newList);
        },
    },

    getters: {
        dashboard(state) {
            return state.dashboard;
        },
        stocks(state) {
            return state.stocksF;
        },
        categories(state) {
            return state.categories;
        },
    },
};
