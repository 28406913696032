import { createRouter, createWebHistory } from "vue-router";
import authService from "./auth";

import MainPage from "@/Main/Main";
import LoginPage from "@/Main/Login";
import ProfilePage from "@/Main/Profile";

import DashBoard from "@/Pages/DashBoard";
import PnlPage from "@/Pages/Pnl";
import StocksPage from "@/Pages/Stocks";

import BuyingsPage from "@/Sklad/Buyings";
import BuyingPage from "@/Sklad/Buying";

import ExpensesPage from "@/Pages/Expenses";
import FinancePage from "@/Pages/Finance";

import ProductsPage from "@/Pages/Products";
import OrdersPage from "@/Pages/Orders";

const routes = [
    {
        path: "/",
        name: "Main",
        component: MainPage,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/profile",
        name: "Profile",
        component: ProfilePage,
    },
    {
        path: "/dashboard",
        name: "DashBoard",
        component: DashBoard,
        meta: { title: "Дашборд" },
    },
    {
        path: "/pnl",
        name: "Pnl",
        component: PnlPage,
        meta: { title: "Pnl" },
    },
    {
        path: "/stocks",
        name: "Stocks",
        component: StocksPage,
        meta: { title: "Остатки" },
    },
    {
        path: "/buyings",
        name: "Buyings",
        component: BuyingsPage,
        meta: { title: "Закупки на склад" },
    },
    {
        path: "/buying",
        name: "Buying",
        component: BuyingPage,
        meta: { title: "Закупка" },
    },

    {
        path: "/expenses",
        name: "Expenses",
        component: ExpensesPage,
        meta: { title: "Расходы" },
    },
    {
        path: "/finance",
        name: "Finance",
        component: FinancePage,
        meta: { title: "Финансы" },
    },

    {
        path: "/products",
        name: "Products",
        component: ProductsPage,
        meta: { title: "Products" },
    },
    {
        path: "/orders",
        name: "Orders",
        component: OrdersPage,
        meta: { title: "Orders" },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "DashBoard";

    if (
        // Avoid an infinite redirect
        to.path !== "/login" &&
        to.path !== "/main" &&
        // make sure the user is authenticated
        !authService.isLogged()
    ) {
        // redirect the user to the login page
        return { name: "Login" };
    }
    window.scrollTo(0, 0);
});
export default router;
