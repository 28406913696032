import IVk from "@/components/icons/IVk";
import IVkAd from "@/components/icons/IVkAd";
import IVkAds from "@/components/icons/IVkAds";
import IVkBook from "@/components/icons/IVkBook";
import IVkCamp from "@/components/icons/IVkCamp";
import IVkDoc from "@/components/icons/IVkDoc";
import IVkMoney from "@/components/icons/IVkMoney";
import IVkUser from "@/components/icons/IVkUser";

import IconAltDown from "@/components/icons/IconAltDown";
import IconAltUp from "@/components/icons/IconAltUp";
import IconBack from "@/components/icons/IconBack";
import IconCalendar from "@/components/icons/IconCalendar";
import IconClock from "@/components/icons/IconClock";
import IconPlus from "@/components/icons/IconPlus";
import IconEdit from "@/components/icons/IconEdit";
import IconDelete from "@/components/icons/IconDelete";
import IconDown from "@/components/icons/IconDown";
import IconDownload from "@/components/icons/IconDownload";
import IconClick from "@/components/icons/IconClick";
import IconClose from "@/components/icons/IconClose";
import IconClone from "@/components/icons/IconClone";
import IconGood from "@/components/icons/IconGood";
import IconBad from "@/components/icons/IconBad";
import IconLink from "@/components/icons/IconLink";
import IconList from "@/components/icons/IconList";
import IconLoader from "@/components/icons/IconLoader";
import IconRe from "@/components/icons/IconRe";
import IconHome from "@/components/icons/IconHome";
import IconMore from "@/components/icons/IconMore";
import IconMinus from "@/components/icons/IconMinus";
import IconOk from "@/components/icons/IconOk";
import IconProtfolio from "@/components/icons/IconProtfolio";
import IconRocket from "@/components/icons/IconRocket";
import IconRound from "@/components/icons/IconRound";
import IconSearch from "@/components/icons/IconSearch";
import IconSetting from "@/components/icons/IconSetting";
import IconStar from "@/components/icons/IconStar";
import IconStop from "@/components/icons/IconStop";
import IconTelegram from "@/components/icons/IconTelegram";
import IconEye from "@/components/icons/IconEye";
import IconEyeClose from "@/components/icons/IconEyeClose";
import IconUp from "@/components/icons/IconUp";
import IconWarning from "@/components/icons/IconWarning";
import IYa from "@/components/icons/IYa";
import ISenler from "@/components/icons/ISenler";

export default [
    IVk,
    IVkAd,
    IVkAds,
    IVkBook,
    IVkCamp,
    IVkDoc,
    IVkMoney,
    IVkUser,

    IconAltDown,
    IconAltUp,
    IconBack,
    IconCalendar,
    IconClock,
    IconGood,
    IconBad,
    IconEdit,
    IconEye,
    IconEyeClose,
    IconDelete,
    IconDown,
    IconDownload,
    IconClick,
    IconClose,
    IconClone,
    IconLink,
    IconList,
    IconLoader,
    IconMore,
    IconMinus,
    IconHome,
    IconOk,
    IconPlus,
    IconProtfolio,
    IconRe,
    IconRocket,
    IconRound,
    IconSearch,
    IconSetting,
    IconStar,
    IconStop,
    IconTelegram,
    IconUp,
    IconWarning,
    IYa,
    ISenler,
];
