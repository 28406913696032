<template>
    <!-- <div class="grid grid-cols-8 bg-white hover:bg-sky-100 hover:text-sky-600 rounded mb-1 p-2">
        <div>Артикул</div>
        <div>Москва и МО</div>
        <div>Центр</div>
        <div>Северо-Запад</div>
        <div>Поволжье</div>
        <div>Юг</div>
        <div>Урал</div>
        <div>Сибирь</div>

        <div v-for="o in result" :key="o" class="grid grid-cols-4 bg-white hover:bg-sky-100 hover:text-sky-600 rounded mb-1 p-2">
            <div>{{ o.id }}</div>
            <div>{{ o.count }}</div>
            <div>{{ o.region }}</div>
        </div>
    </div> -->

    <div class="flex justify-center items-center gap-20 mt-4">
        <div
            @click="(s.status = 1), filterOrders()"
            class="w-48 h-24 flex flex-col justify-center items-center bg-slate-400 rounded-lg cursor-pointer"
        >
            <span class="text-white text-xs uppercase">ожидает упаковки</span>
            <span class="text-white text-xl">{{ sumStatus[1].qty }} | {{ parseFloat(sumStatus[1].sum).toLocaleString() }} ₽</span>
        </div>
        <div @click="(s.status = 2), filterOrders()" class="w-48 h-24 flex flex-col justify-center items-center bg-sky-400 rounded-lg cursor-pointer">
            <span class="text-white text-xs uppercase">ожидает отгрузки</span>
            <span class="text-white text-xl">{{ sumStatus[2].qty }} | {{ parseFloat(sumStatus[2].sum).toLocaleString() }} ₽</span>
        </div>
        <div
            @click="(s.status = 3), filterOrders()"
            class="w-48 h-24 flex flex-col justify-center items-center bg-amber-400 rounded-lg cursor-pointer"
        >
            <span class="text-white text-xs uppercase">доставляется</span>
            <span class="text-white text-xl">{{ sumStatus[3].qty }} | {{ parseFloat(sumStatus[3].sum).toLocaleString() }} ₽</span>
        </div>
        <div
            @click="(s.status = 4), filterOrders()"
            class="w-48 h-24 flex flex-col justify-center items-center bg-teal-400 rounded-lg cursor-pointer"
        >
            <span class="text-white text-xs uppercase">доставлено</span>
            <span class="text-white text-xl">{{ sumStatus[4].qty }} | {{ parseFloat(sumStatus[4].sum).toLocaleString() }} ₽</span>
        </div>
        <div
            @click="(s.status = 5), filterOrders()"
            class="w-48 h-24 flex flex-col justify-center items-center bg-pink-400 rounded-lg cursor-pointer"
        >
            <span class="text-white text-xs uppercase">отменено</span>
            <span class="text-white text-xl">{{ sumStatus[5].qty }} | {{ parseFloat(sumStatus[5].sum).toLocaleString() }} ₽</span>
        </div>
    </div>

    <div class="flex justify-between items-center gap-4 mt-8 mb-2">
        <h2 class="flex items-center gap-2 text-slate-500 text-2xl uppercase">
            ОРДЕРА <span class="count">{{ orders.length }}</span>
        </h2>

        <input type="text" class="input text-sm" placeholder="Поиск" v-model="s.findme" @input="filterOrders()" />

        <input type="date" class="input text-sm w-40" @change="setSettings(s), filterOrders()" v-model="s.start" />
        <input type="date" class="input text-sm w-40" @change="setSettings(s), filterOrders()" v-model="s.end" />

        <div v-if="s.clear" @click="clear()" class="w-20 text-white bg-gray-400 hover:bg-gray-300 text-sm text-center p-1 rounded cursor-pointer">
            Сброс
        </div>
    </div>

    <!-- <div class="grid grid-cols-13 text-slate-400 text-xs uppercase mb-px p-2">
        <div>ID</div>
        <div class="col-span-2">Артикул</div>
        <div>Кол-во</div>
        <div>Цена</div>
        <div class="col-span-2">Регион</div>
        <div class="col-span-3">Склад</div>
        <div class="col-span-2">Дата</div>
        <div>Статус</div>
    </div>
    <div
        v-for="o in orders"
        :key="o"
        class="grid grid-cols-13 bg-white hover:bg-sky-100 hover:text-sky-600 text-sm rounded mb-px p-2"
        :class="{
            '!text-sky-800 !bg-sky-100': o.status == 2,
            '!text-amber-800 !bg-amber-100': o.status == 3,
            '!text-teal-800 !bg-teal-100': o.status == 4,
            '!text-red-800 !bg-red-100': o.status == 5,
        }"
    >
        <div>{{ o.id }}</div>
        <div class="col-span-2">{{ o.offer_id }}</div>
        <div>{{ o.quantity }}</div>
        <div>{{ o.price }}</div>
        <div class="col-span-2">{{ o.region }}</div>
        <div class="col-span-3">{{ o.warehouse }}</div>
        <div class="col-span-2">{{ o.created }}</div>
        <div>{{ statuses[o.status] }}</div>
    </div> -->

    <div class="flex">
        <div class="w-1/2"></div>
        <div class="w-1/2">
            <div
                v-for="o in orders"
                :key="o"
                class="grid grid-cols-11 gap-8 items-center bg-white hover:bg-sky-100 hover:text-sky-600 text-sm rounded mb-2 py-2 px-4"
                :class="{
                    '!text-sky-800 !bg-sky-100': o.status == 2,
                    '!text-amber-800 !bg-amber-100': o.status == 3,
                    '!text-teal-800 !bg-teal-100': o.status == 4,
                    '!text-red-800 !bg-red-100': o.status == 5,
                }"
            >
                <div
                    class="circle-4"
                    :class="{
                        '!bg-sky-600': o.status == 2,
                        '!bg-amber-600': o.status == 3,
                        '!bg-teal-600': o.status == 4,
                        '!bg-red-600': o.status == 5,
                    }"
                ></div>

                <div class="flex flex-col col-span-4">
                    <div class="text-xl">{{ o.offer_id }}</div>
                    <span class="text-xs"></span>
                    <span class="text-xs">{{ statuses[o.status] }}</span>
                </div>

                <div class="text-2xl text-right col-span-2">{{ parseInt(o.price) }} ₽</div>

                <div class="text-sm text-right">{{ o.quantity }} шт</div>

                <div class="flex flex-col col-span-3">
                    <div class="flex justify-between text-xs">
                        <span>#{{ o.id }}</span> <span>{{ moment(o.created).format("D.M h:m") }}</span>
                    </div>
                    <span class="text-xs">{{ o.region }}</span>
                    <span class="text-xs">{{ o.warehouse }}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- <h2 class="text-slate-500 text-2xl uppercase mt-8 mb-2">КЛАСТЕРЫ</h2>
    <div v-for="item in clusters" :key="item" class="grid grid-cols-2 bg-white hover:bg-sky-100 hover:text-sky-600 rounded mb-1 p-2">
        <div>{{ item.region }}</div>
        <div>{{ item.claster }}</div>
    </div> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useDashBoardStore } from "@/store/dashboard";

import moment from "moment";

export default {
    name: "OrdersPage",

    data() {
        return {
            moment: moment,
            regions: {
                1: "Москва и МО",
                2: "Центр",
                3: "Северо-Запад",
                4: "Поволжье",
                5: "Юг",
                6: "Урал",
                7: "Сибирь",
            },

            statuses: {
                1: "упаковка",
                2: "отгрузка",
                3: "доставляется",
                4: "доставлено",
                5: "отменено",
            },
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "setPopup", "closePopup", "setSettings", "setMonth", "setPeriod", "setView"]),
        ...mapState(useDashBoardStore, ["getDashBoard", "dashboard", "stocks", "orders", "categories"]),

        result() {
            if (!this.clusters || !this.orders) return [];

            let data = [];

            this.orders.forEach((order) => {
                if (!data[order.id]) data[order.id] = {};

                // let cluster = this.clusters.filter((item) => item.region == order.region);
                // order.cluster = cluster[0].claster;
                data.push(order);
            });

            return data;
        },

        resultOrders() {
            if (!this.clusters || !this.orders) return [];

            let data = [];

            this.orders.forEach((order) => {
                let cluster = this.clusters.filter((item) => item.region == order.region);
                order.cluster = cluster[0].claster;
                data.push(order);
            });

            return data;
        },

        sumStatus() {
            if (!this.orders) return [];

            let statuses = { 1: { qty: 0, sum: 0 }, 2: { qty: 0, sum: 0 }, 3: { qty: 0, sum: 0 }, 4: { qty: 0, sum: 0 }, 5: { qty: 0, sum: 0 } };

            this.orders.forEach((order) => {
                statuses[order.status].qty += parseFloat(order.quantity);
                statuses[order.status].sum += parseFloat(order.price);
            });

            return statuses;
        },
    },

    mounted() {
        this.getDashBoard();
    },
};
</script>
