<template>
    <!-- <div class="flex justify-center items-center gap-20 mt-4">
        <div
            @click="(s.status = 1), filterOrders()"
            class="w-48 h-24 flex flex-col justify-center items-center bg-slate-400 rounded-lg cursor-pointer"
        >
            <span class="text-white text-xs uppercase">ожидает упаковки</span>
            <span class="text-white text-xl">{{ sumStatus[1].qty }} | {{ parseFloat(sumStatus[1].sum).toLocaleString() }} ₽</span>
        </div>
        <div @click="(s.status = 2), filterOrders()" class="w-48 h-24 flex flex-col justify-center items-center bg-sky-400 rounded-lg cursor-pointer">
            <span class="text-white text-xs uppercase">ожидает отгрузки</span>
            <span class="text-white text-xl">{{ sumStatus[2].qty }} | {{ parseFloat(sumStatus[2].sum).toLocaleString() }} ₽</span>
        </div>
        <div
            @click="(s.status = 3), filterOrders()"
            class="w-48 h-24 flex flex-col justify-center items-center bg-amber-400 rounded-lg cursor-pointer"
        >
            <span class="text-white text-xs uppercase">доставляется</span>
            <span class="text-white text-xl">{{ sumStatus[3].qty }} | {{ parseFloat(sumStatus[3].sum).toLocaleString() }} ₽</span>
        </div>
        <div
            @click="(s.status = 4), filterOrders()"
            class="w-48 h-24 flex flex-col justify-center items-center bg-teal-400 rounded-lg cursor-pointer"
        >
            <span class="text-white text-xs uppercase">доставлено</span>
            <span class="text-white text-xl">{{ sumStatus[4].qty }} | {{ parseFloat(sumStatus[4].sum).toLocaleString() }} ₽</span>
        </div>
        <div
            @click="(s.status = 5), filterOrders()"
            class="w-48 h-24 flex flex-col justify-center items-center bg-pink-400 rounded-lg cursor-pointer"
        >
            <span class="text-white text-xs uppercase">отменено</span>
            <span class="text-white text-xl">{{ sumStatus[5].qty }} | {{ parseFloat(sumStatus[5].sum).toLocaleString() }} ₽</span>
        </div>
    </div> -->

    <div class="flex justify-between items-center gap-4 mt-4 mb-2">
        <h2 class="flex items-center gap-2 text-slate-500 text-2xl uppercase">
            Закупки <span class="count">{{ buyings.length }}</span>
        </h2>

        <!-- <input type="text" class="input text-sm" placeholder="Поиск" v-model="s.findme" @input="filterOrders()" />

        <input type="date" class="input text-sm w-40" @change="setSettings(s), filterOrders()" v-model="s.start" />
        <input type="date" class="input text-sm w-40" @change="setSettings(s), filterOrders()" v-model="s.end" />

        <div v-if="s.clear" @click="clear()" class="w-20 text-white bg-gray-400 hover:bg-gray-300 text-sm text-center p-1 rounded cursor-pointer">
            Сброс
        </div> -->

        <div class="btn btn-green cursor-pointer text-white" @click="createBuying()"><IconPlus :size="5" /></div>
    </div>

    <div class="grid grid-cols-12 gap-4 py-1 px-6 w-full text-white/30 text-xs uppercase">
        <div>ID</div>
        <div>ПОСТАВЩИК</div>
        <div class="col-span-2">ТРЕК</div>
        <div class="text-right">СЕБЕС</div>
        <div class="text-right">ДОСТАВКА</div>
        <div class="text-center">ВЕС</div>
        <div>СОЗДАНА</div>
        <div>ОПЛАЧЕНА</div>
        <div>ОТПРАВЛЕНА</div>
        <div>ПОЛУЧЕНА</div>
        <div>СТАТУС</div>
    </div>

    <div v-for="item in buyings" :key="item" class="py-px w-full group text-white/50 hover:py-1 hover:text-sky-400 relative duration-500">
        <div class="grid grid-cols-12 gap-4 box gradient2 p-4 w-full text-sm rounded pr-10">
            <div>{{ item.id }}</div>
            <div>{{ item.supplier_name }}</div>
            <div class="col-span-2">{{ item.track }}</div>
            <div class="text-right">{{ item.cost }} ₽</div>
            <div class="text-right">{{ item.delivery }} ₽</div>
            <div class="text-center">{{ item.weight }} кг</div>

            <div>{{ moment(item.created).format("DD.MM.YY") }}</div>

            <div v-if="item.payed">{{ moment(item.payed).format("DD.MM.YY") }}</div>
            <div v-else>-</div>

            <div v-if="item.sended">{{ moment(item.sended).format("DD.MM.YY") }}</div>
            <div v-else>-</div>

            <div v-if="item.arrived">{{ moment(item.arrived).format("DD.MM.YY") }}</div>
            <div v-else>-</div>

            <div class="flex justify-between items-center gap-4">
                <div class="flex items-center gap-2">
                    <span
                        class="circle"
                        :class="{
                            '!bg-gray-400': item.status == 1,
                            '!bg-sky-400': item.status == 2,
                            '!bg-indigo-400': item.status == 3,
                            '!bg-amber-400': item.status == 4,
                            '!bg-teal-400': item.status == 5,
                        }"
                    ></span>
                    {{ statuses[item.status] }}
                </div>
                <div
                    class="absolute right-4 flex justify-end cursor-pointer opacity-0 -mr-4 group-hover:opacity-100 group-hover:mr-0 duration-300"
                    @click="pickBuying(item), $router.push('/buying')"
                >
                    <IconEdit :size="4" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useSkladStore } from "@/store/sklad";

import moment from "moment";

export default {
    name: "BuyingsPage",

    data() {
        return {
            moment: moment,
            statuses: {
                1: "Создана",
                2: "План",
                3: "Оплачена",
                4: "Отправлена",
                5: "Получена",
            },
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "setPopup", "closePopup", "setSettings", "setMonth", "setPeriod", "setView"]),
        ...mapState(useSkladStore, ["getSklad", "createBuying", "pickBuying", "buyings", "buying"]),
    },

    methods: {
        clear() {
            this.clearSettings();
            this.fillOrders();
        },
    },

    mounted() {
        this.getSklad();
    },
};
</script>
