<template>
    <div v-if="s" class="flex justify-between items-center mb-4 gap-2">
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 gradient rounded w-10 h-10 uppercase max-sm:hidden"
            :class="{ '!bg-white/50  !text-white': s.start == '2023-01-01' && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: '2022-01-01', end: moment().format('YYYY-MM-DD') })"
        >
            ВСЕГО
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 gradient rounded w-10 h-10 uppercase max-sm:hidden"
            :class="{ '!bg-white/50 !text-white': s.start == moment().format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD') }"
            @click="setPeriod({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            СЕЙЧАС
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 gradient rounded w-10 h-10 uppercase max-sm:hidden"
            :class="{
                '!bg-white/50 !text-white':
                    s.start == moment().subtract(1, 'days').format('YYYY-MM-DD') && s.end == moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end: moment().subtract(1, 'days').format('YYYY-MM-DD') })"
        >
            ВЧЕРА
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 gradient rounded w-10 h-10 uppercase max-sm:hidden"
            :class="{
                '!bg-white/50 !text-white': s.start == moment().subtract(6, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            7
        </div>
        <div
            class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 gradient rounded w-10 h-10 uppercase max-sm:hidden"
            :class="{
                '!bg-white/50 !text-white': s.start == moment().subtract(29, 'days').format('YYYY-MM-DD') && s.end == moment().format('YYYY-MM-DD'),
            }"
            @click="setPeriod({ start: moment().subtract(29, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })"
        >
            30
        </div>
        <div v-for="(value, key) in monthShort" :key="key" class="max-sm:hidden">
            <div
                class="flex justify-center items-center cursor-pointer text-[9px] text-slate-500 gradient rounded w-10 h-10 uppercase"
                :class="{ '!bg-white/50 !text-white': s.month == key }"
                @click="setMonth(key)"
            >
                {{ value }}
            </div>
        </div>

        <input type="date" class="input gradient text-xs w-32 max-sm:w-full" @change="setSettings(s)" v-model="s.start" />
        <input type="date" class="input gradient text-xs w-32 max-sm:w-full" @change="setSettings(s)" v-model="s.end" />
    </div>

    <div v-if="dashboard?.length > 0" class="lg:grid lg:grid-cols-4 justify-center items-center gap-4 mt-4 text-white">
        <!-- <div class="box gradient col-span-4 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">ОСТАТКИ</span>
        </div> -->

        <div class="box gradient h-36 col-span-2 max-sm:mb-4 cursor-pointer group" @click="setPopup('salesbycategory')">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Оборот</span>
            <span class="text-5xl">{{ parseInt(stat.revenue).toLocaleString() }} ₽</span>
            <span
                class="flex justify-center items-center opacity-25 group-hover:opacity-100 duration-300"
                :class="{ '!text-teal-500': stat.delta_revenue > 0, '!text-pink-500': stat.delta_revenue < 0 }"
            >
                <!-- <IconUp /> -->
                <span v-if="stat.delta_revenue > 0">+</span>
                {{ parseInt(stat.delta_revenue).toLocaleString() }} ₽
            </span>
        </div>

        <div class="box gradient h-full col-span-2 lg:row-span-2 max-sm:mb-4">
            <div v-if="graf" class="w-full"><LineGraf :id="1" :graf="graf" h="h-[250px]" /></div>
        </div>

        <div class="box gradient h-36 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Кол-во проданных товаров</span>
            <span class="text-5xl">{{ parseInt(stat.solditems).toLocaleString() }} шт.</span>
            <span
                class="flex opacity-25 group-hover:opacity-100 duration-300"
                :class="{ '!text-teal-500': stat.delta_solditems > 0, '!text-pink-500': stat.delta_solditems < 0 }"
            >
                <span v-if="stat.delta_solditems > 0">+</span>
                {{ parseInt(stat.delta_solditems).toLocaleString() }}
            </span>
        </div>

        <div class="box gradient h-36 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Средний чек</span>
            <span class="text-5xl">{{ parseInt(stat.average_check).toLocaleString() }} ₽</span>
            <span
                class="flex opacity-25 group-hover:opacity-100 duration-300"
                :class="{ '!text-teal-500': stat.delta_check > 0, '!text-pink-500': stat.delta_check < 0 }"
            >
                <span v-if="stat.delta_check > 0">+</span>
                {{ parseInt(stat.delta_check).toLocaleString() }}
            </span>
        </div>

        <div class="box gradient h-36 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Затраты на маркетинг</span>
            <!-- <span class="text-5xl">{{ parseInt(stat.marketing_expenses).toLocaleString() }} ₽</span> -->
        </div>

        <div class="box gradient h-full row-span-3 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Воронка</span>
            <div class="flex flex-col w-full justify-between items-center text-center mt-2">
                <div class="funnel !w-48 flex flex-col">
                    <span class="text-4xl">{{ stat.view.toLocaleString() }}</span>
                    <span class="flex justify-center text-blue-400 -mt-2 opacity-25 group-hover:opacity-100 duration-300">Просмотры 100%</span>
                </div>
                <div class="funnel !w-44 flex flex-col">
                    <span class="text-4xl">{{ stat.visit.toLocaleString() }}</span>
                    <span class="flex justify-center text-blue-400 -mt-2 opacity-25 group-hover:opacity-100 duration-300"
                        >В карточку {{ parseFloat(stat.visit / (stat.view / 100)).toFixed(2) }}%</span
                    >
                </div>
                <div class="funnel !w-40 flex flex-col">
                    <span class="text-4xl">{{ stat.to_cart.toLocaleString() }}</span>
                    <span class="flex justify-center text-blue-400 -mt-2 opacity-25 group-hover:opacity-100 duration-300"
                        >В корзину {{ parseFloat(stat.to_cart / (stat.visit / 100)).toFixed(2) }}%</span
                    >
                </div>
                <div class="funnel !w-36 flex flex-col">
                    <span class="text-4xl">{{ stat.solditems.toLocaleString() }}</span>
                    <span class="flex justify-center text-blue-400 -mt-2 opacity-25 group-hover:opacity-100 duration-300"
                        >Заказ {{ parseFloat(stat.solditems / (stat.to_cart / 100)).toFixed(2) }}%</span
                    >
                </div>
            </div>
        </div>

        <div class="box gradient h-36 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Количество возвратов и отказов</span>
            <span class="text-5xl">{{ parseInt(stat.returns).toLocaleString() }} шт.</span>
        </div>

        <div class="box gradient h-36 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Количество запасов товара в днях</span>
            <span class="text-5xl">{{ parseInt(stat.turnover / stat.count).toLocaleString() }} дн.</span>
        </div>

        <div class="box gradient h-36 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Конверсия из просмотра в продажу</span>
            <span class="text-5xl">{{ parseFloat(stat.conversion_rate).toFixed(2) }}%</span>
            <span
                class="flex opacity-25 group-hover:opacity-100 duration-300"
                :class="{ '!text-teal-500': stat.delta_conversion_rate > 0, '!text-pink-500': stat.delta_conversion_rate < 0 }"
            >
                <span v-if="stat.delta_conversion_rate > 0">+</span>
                {{ parseFloat(stat.delta_conversion_rate).toLocaleString() }}
            </span>
        </div>

        <div class="box gradient h-36 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Уровень удовлетворенности клиентов</span>
            <span class="text-5xl">{{ parseFloat(stat.customer_satisfaction / stat.count) }} </span>
        </div>

        <div class="box gradient h-36 max-sm:mb-4 cursor-pointer group" @click="setPopup('stocks')">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Остатки</span>
            <div class="flex justify-between w-full">
                <div class="flex flex-col items-center">
                    <span class="text-3xl"> {{ stat.stock_free }}</span>
                    <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">В НАЛИЧИИ</span>
                </div>
                <div class="flex flex-col items-center">
                    <span class="text-3xl"> {{ stat.stock_promised }}</span>
                    <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">В ПУТИ</span>
                </div>
                <div class="flex flex-col items-center">
                    <span class="text-3xl"> {{ stat.stock_sell1 }}</span>
                    <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">ЗА 1 МЕС</span>
                </div>
                <div class="flex flex-col items-center">
                    <span class="text-3xl"> {{ stat.stock_sell3 }}</span>
                    <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">ЗА 3 МЕС</span>
                </div>
            </div>
        </div>

        <!-- <div class="box gradient h-36 max-sm:mb-4 group">
            <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Топ 3 категории</span>
            <div class="text-2xl" v-for="(cat, key) in dash?.salesbycategory" :key="cat">{{ key }} : {{ cat }}</div>
        </div> -->
    </div>

    <template v-if="s.popup == 'stocks'"><Stocks /> </template>
    <template v-if="s.popup == 'salesbycategory' && stat?.salesbycategory"><SalesByCategory :salesbycategory="stat.salesbycategory" /> </template>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useDashBoardStore } from "@/store/dashboard";

import moment from "moment";

// import { mapActions, mapGetters } from "vuex";

import Stocks from "../components/Stocks.vue";
import SalesByCategory from "../components/SalesByCategory.vue";
import LineGraf from "../components/LineGraf.vue";

export default {
    name: "DashBoard",

    components: { Stocks, SalesByCategory, LineGraf },

    data() {
        return {
            moment: moment,
            radiobtn: "revenue",
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "setPopup", "closePopup", "setSettings", "setMonth", "setPeriod", "setView"]),
        ...mapState(useDashBoardStore, ["getDashBoard", "dashboard", "stocks", "categories"]),

        stat() {
            if (!this.dashboard) return [];

            let result = {
                revenue: 0,
                delta_revenue: 0,
                margin: 0,
                solditems: 0,
                delta_solditems: 0,
                average_check: 0,
                delta_check: 0,
                turnover: 0,
                returns: 0,
                conversion_rate: 0,
                marketing_expenses: 0,
                customer_satisfaction: 0,
                salesbycategory: {},
                view: 0,
                visit: 0,
                to_cart: 0,
                count: 0,
                stock_free: 0,
                stock_promised: 0,
                stock_sell1: 0,
                stock_sell3: 0,
            };
            let cats = null;
            let all = { revenue: 0, solditems: 0, view: 0, count: 0 };

            this.dashboard.forEach((item) => {
                let date = moment(item.date).format("YYYY-MM-DD");

                if (date >= this.s.start && date <= this.s.end) {
                    result.count++;
                    if (item.revenue) result.revenue += parseFloat(item.revenue);
                    if (item.margin) result.margin += parseFloat(item.margin);
                    if (item.solditems) result.solditems += parseFloat(item.solditems);
                    // if (item.average_check) result.average_check += parseFloat(item.average_check);
                    if (item.turnover) result.turnover += parseFloat(item.turnover);
                    if (item.returns) result.returns += parseFloat(item.returns);
                    // if (item.conversion_rate) result.conversion_rate += parseFloat(item.conversion_rate);
                    if (item.marketing_expenses) result.marketing_expenses += parseFloat(item.marketing_expenses);
                    if (item.customer_satisfaction) result.customer_satisfaction += parseFloat(item.customer_satisfaction);

                    // воронка
                    if (item.view) result.view += parseInt(item.view);
                    if (item.visit) result.visit += parseInt(item.visit);
                    if (item.to_cart) result.to_cart += parseInt(item.to_cart);

                    // категории
                    if (item.salesbycategory) cats = JSON.parse(item.salesbycategory);

                    if (cats) {
                        cats.forEach((cat) => {
                            if (!result.salesbycategory[cat.c])
                                result.salesbycategory[cat.c] = { id: cat.c, revenue: 0, solditems: 0, com: 0, dev: 0, ref: 0 };

                            result.salesbycategory[cat.c].revenue += parseInt(cat.r);
                            result.salesbycategory[cat.c].solditems += parseInt(cat.s);
                            if (cat.com) result.salesbycategory[cat.c].com += parseInt(cat.com);
                            if (cat.dev) result.salesbycategory[cat.c].dev += parseInt(cat.dev);
                            if (cat.ref) result.salesbycategory[cat.c].ref += parseInt(cat.ref);
                        });
                    }
                }

                // ЗА ВСЕ ВРЕМЯ
                all.count++;
                if (item.revenue) all.revenue += parseFloat(item.revenue);
                if (item.solditems) all.solditems += parseFloat(item.solditems);

                // воронка
                if (item.view) all.view += parseInt(item.view);
            });

            // средний чек за выбранный период и за весь
            result.average_check = parseInt(result.revenue / result.solditems);
            all.average_check = parseInt(all.revenue / all.solditems);

            // конверсия в продажу
            result.conversion_rate = parseFloat(result.solditems / (result.view / 100)).toFixed(2);
            all.conversion_rate = parseFloat(all.solditems / (all.view / 100)).toFixed(2);

            // цифры изменения по сравнению со средней за все время
            result.delta_revenue = result.revenue - all.revenue / (all.count / result.count);
            result.delta_solditems = result.solditems - all.solditems / (all.count / result.count);
            result.delta_check = result.average_check - all.average_check;
            result.delta_conversion_rate = result.conversion_rate - all.conversion_rate;

            // категории: преобразуем в массив и отсортируем по убыванию оборота
            result.salesbycategory = Object.values(result.salesbycategory);
            result.salesbycategory.sort((a, b) => parseInt(b.revenue) - parseInt(a.revenue));

            // остатки
            this.stocks.forEach((stock) => {
                result.stock_free +=
                    stock.cluster1.free +
                    stock.cluster2.free +
                    stock.cluster3.free +
                    stock.cluster4.free +
                    stock.cluster5.free +
                    stock.cluster6.free +
                    stock.cluster7.free +
                    stock.cluster8.free;

                result.stock_promised +=
                    stock.cluster1.promised +
                    stock.cluster2.promised +
                    stock.cluster3.promised +
                    stock.cluster4.promised +
                    stock.cluster5.promised +
                    stock.cluster6.promised +
                    stock.cluster7.promised +
                    stock.cluster8.promised;

                result.stock_sell1 +=
                    stock.cluster1.sell1 +
                    stock.cluster2.sell1 +
                    stock.cluster3.sell1 +
                    stock.cluster4.sell1 +
                    stock.cluster5.sell1 +
                    stock.cluster6.sell1 +
                    stock.cluster7.sell1 +
                    stock.cluster8.sell1;

                result.stock_sell3 +=
                    stock.cluster1.sell3 +
                    stock.cluster2.sell3 +
                    stock.cluster3.sell3 +
                    stock.cluster4.sell3 +
                    stock.cluster5.sell3 +
                    stock.cluster6.sell3 +
                    stock.cluster7.sell3 +
                    stock.cluster8.sell3;
            });

            return result;
        },

        graf() {
            if (!this.dashboard) return [];

            let graf = [];

            let srt = moment("2022-01-01").format("YYYY-MM");
            let end = moment().format("YYYY-MM");

            while (srt <= end) {
                let row = { time: moment(srt).format("YYYY-MM-DD"), value: 0 };

                this.dashboard.forEach((dash) => {
                    if (moment(dash.date).format("YYYY-MM") == srt) {
                        if (this.radiobtn == "revenue" && parseInt(dash.revenue) > 0) row.value += parseInt(dash.revenue);
                    }
                });

                graf.push(row);
                srt = moment(srt).add(1, "month").format("YYYY-MM");
            }

            return graf;
        },
    },

    // methods: {
    //     ...mapActions(["getDashBoard", "setPopup", "setSettings", "setPeriod", "setMonth"]),
    // },

    mounted() {
        this.getDashBoard();
    },
};
</script>

<style scoped>
.funnel {
    position: relative !important;
    perspective: 100px;
    width: 100%;
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 3px;
}

.funnel:before {
    position: absolute;
    content: "";
    border: 1px solid rgba(255, 255, 255, 0.25);
    transform: rotateX(-10deg);
    transform-origin: 50% 100%;
    border-radius: 5px 5px 10px 10px;
    height: 100%;
    width: 100%;
}
</style>
