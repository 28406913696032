import API from "../../services/api";

export default {
    state: {
        clusters: [],
        clustersF: [],
        cluster: {},

        products: [],
        productsF: [],
        product: {},

        store: [],

        orders: [],
        ordersF: [],
        order: {},
    },

    mutations: {
        setClusters(state, clusters) {
            state.clusters = clusters;
            state.clustersF = clusters;
        },
        setClustersF(state, clusters) {
            state.clustersF = clusters;
        },
        setCluster(state, cluster) {
            localStorage.setItem("cluster", JSON.stringify(cluster));
            state.cluster = cluster;
        },

        setStore(state, store) {
            state.store = store;
        },

        setProducts(state, products) {
            state.products = products;
            state.productsF = products;
        },
        setProductsF(state, products) {
            state.productsF = products;
        },
        setProduct(state, product) {
            localStorage.setItem("product", JSON.stringify(product));
            state.product = product;
        },

        setOrders(state, orders) {
            state.orders = orders;
            state.ordersF = orders;
        },
        setOrdersF(state, orders) {
            state.ordersF = orders;
        },
        setOrder(state, order) {
            localStorage.setItem("order", JSON.stringify(order));
            state.order = order;
        },
    },

    actions: {
        getAllOLD(ctx) {
            let cluster = [
                { region: "Московская", claster: "Москва и МО" },
                { region: "Москва", claster: "Москва и МО" },
                { region: "Архангельская", claster: "Центр" },
                { region: "Брянская", claster: "Центр" },
                { region: "Владимирская", claster: "Центр" },
                { region: "Ивановская", claster: "Центр" },
                { region: "Калужская", claster: "Центр" },
                { region: "Костромская", claster: "Центр" },
                { region: "Курская", claster: "Центр" },
                { region: "Липецкая", claster: "Центр" },
                { region: "Ненецкий автономный округ", claster: "Центр" },
                { region: "Орловская", claster: "Центр" },
                { region: "Коми", claster: "Центр" },
                { region: "Рязанская", claster: "Центр" },
                { region: "Смоленская", claster: "Центр" },
                { region: "Тамбовская", claster: "Центр" },
                { region: "Тверская", claster: "Центр" },
                { region: "Тульская", claster: "Центр" },
                { region: "Ярославская", claster: "Центр" },
                { region: "Камчатский", claster: "Центр" },
                { region: "Магаданская", claster: "Центр" },
                { region: "Саха", Яclaster: "кути", claster: "Центр" },
                { region: "Сахалинская", claster: "Центр" },
                { region: "Чукотский автономный округ", claster: "Центр" },
                { region: "г Норильск", claster: "Центр" },
                { region: "г Дудинка", claster: "Центр" },
                { region: "Вологодская", claster: "Северо-Запад" },
                { region: "Ленинградская", claster: "Северо-Запад" },
                { region: "Мурманская", claster: "Северо-Запад" },
                { region: "Новгородская", claster: "Северо-Запад" },
                { region: "Псковская", claster: "Северо-Запад" },
                { region: "Карелия", claster: "Северо-Запад" },
                { region: "Санкт-Петербург", claster: "Северо-Запад" },
                { region: "Кировская", claster: "Поволжье" },
                { region: "Нижегородская", claster: "Поволжье" },
                { region: "Оренбургская", claster: "Поволжье" },
                { region: "Пензенская", claster: "Поволжье" },
                { region: "Самарская", claster: "Поволжье" },
                { region: "Саратовская", claster: "Поволжье" },
                { region: "Ульяновская", claster: "Поволжье" },
                { region: "Удмуртская", claster: "Поволжье" },
                { region: "Марий Эл", claster: "Поволжье" },
                { region: "Татарстан", claster: "Поволжье" },
                { region: "Мордовия", claster: "Поволжье" },
                { region: "Башкортостан", claster: "Поволжье" },
                { region: "Чувашская Республика", claster: "Поволжье" },
                { region: "Чувашская Республика - Чувашия", claster: "Поволжье" },
                { region: "Астраханская", claster: "Юг" },
                { region: "Белгородская", claster: "Юг" },
                { region: "Волгоградская", claster: "Юг" },
                { region: "Воронежская", claster: "Юг" },
                { region: "Краснодарский", claster: "Юг" },
                { region: "Крым", claster: "Юг" },
                { region: "Ростовская", claster: "Юг" },
                { region: "Ставропольский", claster: "Юг" },
                { region: "Чеченская", claster: "Юг" },
                { region: "Республика Калмыкия", claster: "Юг" },
                { region: "Адыгея", claster: "Юг" },
                { region: "Дагестан", claster: "Юг" },
                { region: "Ингушетия", claster: "Юг" },
                { region: "Кабардино-Балкарская", claster: "Юг" },
                { region: "Карачаево-Черкесская", claster: "Юг" },
                { region: "Северная Осетия - Алания", claster: "Юг" },
                { region: "Севастополь", claster: "Юг" },
                { region: "Курганская", claster: "Урал" },
                { region: "Пермский", claster: "Урал" },
                { region: "Свердловская", claster: "Урал" },
                { region: "Тюменская", claster: "Урал" },
                { region: "Ханты-Мансийский автономный округ - Югра", claster: "Урал" },
                { region: "Челябинская", claster: "Урал" },
                { region: "Ямало-Ненецкий", claster: "Урал" },
                { region: "Алтайский", claster: "Сибирь" },
                { region: "Кемеровская", claster: "Сибирь" },
                { region: "Кемеровская область - Кузбасс", claster: "Сибирь" },
                { region: "Новосибирская", claster: "Сибирь" },
                { region: "Омская", claster: "Сибирь" },
                { region: "Республика Алтай", claster: "Сибирь" },
                { region: "Томская", claster: "Сибирь" },
                { region: "Забайкальский", claster: "Сибирь" },
                { region: "Иркутская", claster: "Сибирь" },
                { region: "Красноярский", claster: "Сибирь" },
                { region: "Хакасия", claster: "Сибирь" },
                { region: "Тыва", claster: "Сибирь" },
                { region: "Бурятия", claster: "Сибирь" },
                { region: "Амурская", claster: "Дальний Восток" },
                { region: "Еврейская", claster: "Дальний Восток" },
                { region: "Приморский", claster: "Дальний Восток" },
                { region: "Хабаровский", claster: "Дальний Восток" },
                { region: "Калининградская", claster: "Калининград" },
                { region: "Республика Казахстан", claster: "Казахстан" },
                { region: "Республика Беларусь", claster: "Республика Беларусь" },
                { region: "Могилевская", claster: "Республика Беларусь" },
                { region: "Минск", claster: "Республика Беларусь" },
                { region: "Алматы", claster: "Казахстан" },
                { region: "Восточно-Казахстанская", claster: "Казахстан" },
                { region: "Гродненская", claster: "Республика Беларусь" },
                { region: "Брестская", claster: "Республика Беларусь" },
                { region: "Карагандинская", claster: "Казахстан" },
                { region: "Астана", claster: "Казахстан" },
                { region: "Алматинская", claster: "Казахстан" },
                { region: "Гомельская", claster: "Республика Беларусь" },
                { region: "Минская", claster: "Республика Беларусь" },
                { region: "Западно-Казахстанская", claster: "Казахстан" },
            ];

            let orders = [
                { id: "POD-SADJ-CHERP", count: 1, region: "Ленинградская" },
                { id: "COVER-sheep85-2-K", count: 1, region: "Чувашская Республика" },
                { id: "skull", count: 1, region: "Москва" },
                { id: "COVER-sheep85-2-W", count: 1, region: "Московская" },
                { id: "COVER-sheep85-2-K", count: 1, region: "Тверская" },
                { id: "COVER-sheep85-2-K", count: 1, region: "Ленинградская" },
                { id: "COVER-sheep85-2-K", count: 1, region: "Башкортостан" },
                { id: "POD-SADJ-BLACK", count: 1, region: "Москва" },
                { id: "skull", count: 1, region: "Краснодарский" },
                { id: "EL-TRAIN", count: 1, region: "Крым" },
                { id: "GATEK74-30", count: 1, region: "Калининградская" },
                { id: "GATEK55-07", count: 1, region: "Калининградская" },
                { id: "COVER-sheep85-2-K", count: 1, region: "Санкт-Петербург" },
                { id: "toilet-close-blue", count: 1, region: "Ленинградская" },
                { id: "toilet-close-blue", count: 1, region: "Ленинградская" },
                { id: "SATA-218-K", count: 1, region: "Москва" },
                { id: "EL-TRAIN", count: 1, region: "Башкортостан" },
                { id: "COVER-sheep85-2-W", count: 1, region: "Челябинская" },
                { id: "POD-SADJ-CHERP", count: 1, region: "Краснодарский" },
                { id: "toilet-close-coffee", count: 1, region: "Самарская" },
            ];

            // orders.forEach((order) => {});

            ctx.commit("setClusters", cluster);
            ctx.commit("setOrders", orders);
        },

        getProducts(ctx, period = 3) {
            ctx.dispatch("request", true);
            // API.GET("api/products").then((res) => {
            //     if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            //     ctx.commit("setProducts", res.data.products);
            //     ctx.dispatch("request", false);
            // });

            API.GET("api/store", { period: period }).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setProducts", res.data.products);
                ctx.commit("setStore", res.data.store);
                ctx.dispatch("request", false);
            });
        },

        getOrders(ctx) {
            let cluster = [
                { region: "Московская", claster: "Москва и МО" },
                { region: "Москва", claster: "Москва и МО" },
                { region: "Архангельская", claster: "Центр" },
                { region: "Брянская", claster: "Центр" },
                { region: "Владимирская", claster: "Центр" },
                { region: "Ивановская", claster: "Центр" },
                { region: "Калужская", claster: "Центр" },
                { region: "Костромская", claster: "Центр" },
                { region: "Курская", claster: "Центр" },
                { region: "Липецкая", claster: "Центр" },
                { region: "Ненецкий автономный округ", claster: "Центр" },
                { region: "Орловская", claster: "Центр" },
                { region: "Коми", claster: "Центр" },
                { region: "Рязанская", claster: "Центр" },
                { region: "Смоленская", claster: "Центр" },
                { region: "Тамбовская", claster: "Центр" },
                { region: "Тверская", claster: "Центр" },
                { region: "Тульская", claster: "Центр" },
                { region: "Ярославская", claster: "Центр" },
                { region: "Камчатский", claster: "Центр" },
                { region: "Магаданская", claster: "Центр" },
                { region: "Саха", Яclaster: "кути", claster: "Центр" },
                { region: "Сахалинская", claster: "Центр" },
                { region: "Чукотский автономный округ", claster: "Центр" },
                { region: "г Норильск", claster: "Центр" },
                { region: "г Дудинка", claster: "Центр" },
                { region: "Вологодская", claster: "Северо-Запад" },
                { region: "Ленинградская", claster: "Северо-Запад" },
                { region: "Мурманская", claster: "Северо-Запад" },
                { region: "Новгородская", claster: "Северо-Запад" },
                { region: "Псковская", claster: "Северо-Запад" },
                { region: "Карелия", claster: "Северо-Запад" },
                { region: "Санкт-Петербург", claster: "Северо-Запад" },
                { region: "Кировская", claster: "Поволжье" },
                { region: "Нижегородская", claster: "Поволжье" },
                { region: "Оренбургская", claster: "Поволжье" },
                { region: "Пензенская", claster: "Поволжье" },
                { region: "Самарская", claster: "Поволжье" },
                { region: "Саратовская", claster: "Поволжье" },
                { region: "Ульяновская", claster: "Поволжье" },
                { region: "Удмуртская", claster: "Поволжье" },
                { region: "Марий Эл", claster: "Поволжье" },
                { region: "Татарстан", claster: "Поволжье" },
                { region: "Мордовия", claster: "Поволжье" },
                { region: "Башкортостан", claster: "Поволжье" },
                { region: "Чувашская Республика", claster: "Поволжье" },
                { region: "Чувашская Республика - Чувашия", claster: "Поволжье" },
                { region: "Астраханская", claster: "Юг" },
                { region: "Белгородская", claster: "Юг" },
                { region: "Волгоградская", claster: "Юг" },
                { region: "Воронежская", claster: "Юг" },
                { region: "Краснодарский", claster: "Юг" },
                { region: "Крым", claster: "Юг" },
                { region: "Ростовская", claster: "Юг" },
                { region: "Ставропольский", claster: "Юг" },
                { region: "Чеченская", claster: "Юг" },
                { region: "Республика Калмыкия", claster: "Юг" },
                { region: "Адыгея", claster: "Юг" },
                { region: "Дагестан", claster: "Юг" },
                { region: "Ингушетия", claster: "Юг" },
                { region: "Кабардино-Балкарская", claster: "Юг" },
                { region: "Карачаево-Черкесская", claster: "Юг" },
                { region: "Северная Осетия - Алания", claster: "Юг" },
                { region: "Севастополь", claster: "Юг" },
                { region: "Курганская", claster: "Урал" },
                { region: "Пермский", claster: "Урал" },
                { region: "Свердловская", claster: "Урал" },
                { region: "Тюменская", claster: "Урал" },
                { region: "Ханты-Мансийский автономный округ - Югра", claster: "Урал" },
                { region: "Челябинская", claster: "Урал" },
                { region: "Ямало-Ненецкий", claster: "Урал" },
                { region: "Алтайский", claster: "Сибирь" },
                { region: "Кемеровская", claster: "Сибирь" },
                { region: "Кемеровская область - Кузбасс", claster: "Сибирь" },
                { region: "Новосибирская", claster: "Сибирь" },
                { region: "Омская", claster: "Сибирь" },
                { region: "Республика Алтай", claster: "Сибирь" },
                { region: "Томская", claster: "Сибирь" },
                { region: "Забайкальский", claster: "Сибирь" },
                { region: "Иркутская", claster: "Сибирь" },
                { region: "Красноярский", claster: "Сибирь" },
                { region: "Хакасия", claster: "Сибирь" },
                { region: "Тыва", claster: "Сибирь" },
                { region: "Бурятия", claster: "Сибирь" },
                { region: "Амурская", claster: "Дальний Восток" },
                { region: "Еврейская", claster: "Дальний Восток" },
                { region: "Приморский", claster: "Дальний Восток" },
                { region: "Хабаровский", claster: "Дальний Восток" },
                { region: "Калининградская", claster: "Калининград" },
                { region: "Республика Казахстан", claster: "Казахстан" },
                { region: "Республика Беларусь", claster: "Республика Беларусь" },
                { region: "Могилевская", claster: "Республика Беларусь" },
                { region: "Минск", claster: "Республика Беларусь" },
                { region: "Алматы", claster: "Казахстан" },
                { region: "Восточно-Казахстанская", claster: "Казахстан" },
                { region: "Гродненская", claster: "Республика Беларусь" },
                { region: "Брестская", claster: "Республика Беларусь" },
                { region: "Карагандинская", claster: "Казахстан" },
                { region: "Астана", claster: "Казахстан" },
                { region: "Алматинская", claster: "Казахстан" },
                { region: "Гомельская", claster: "Республика Беларусь" },
                { region: "Минская", claster: "Республика Беларусь" },
                { region: "Западно-Казахстанская", claster: "Казахстан" },
            ];

            ctx.dispatch("request", true);
            API.GET("api/orders").then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setOrders", res.data.orders);
                ctx.commit("setClusters", cluster);
                ctx.dispatch("request", false);
            });
        },

        fillOrders(ctx) {
            ctx.commit("setOrdersF", ctx.state.orders);
        },

        pickCluster(ctx, cluster) {
            ctx.commit("setCluster", cluster);
        },

        filterOrders(ctx) {
            if (!ctx.state.orders) return null;

            let newList = ctx.state.orders;

            if (ctx.rootState.s.findme != "" && ctx.rootState.s.findme != undefined) {
                newList = newList.filter((item) => {
                    let offer_id = null;
                    let warehouse = null;

                    if (item.offer_id) offer_id = item.offer_id.toString().toLowerCase().indexOf(ctx.rootState.s.findme.toLowerCase()) !== -1;
                    if (item.warehouse) warehouse = item.warehouse.toString().toLowerCase().indexOf(ctx.rootState.s.findme.toLowerCase()) !== -1;
                    if (offer_id || warehouse) return true;

                    return false;
                });
            }

            if (ctx.rootState.s.start != "" && ctx.rootState.s.start != undefined) {
                newList = newList.filter((item) => item.created.substring(0, 10) >= ctx.rootState.s.start);
            }

            if (ctx.rootState.s.end != "" && ctx.rootState.s.end != undefined) {
                newList = newList.filter((item) => item.created.substring(0, 10) <= ctx.rootState.s.end);
            }

            if (ctx.rootState.s.status != "" && ctx.rootState.s.status != undefined) {
                newList = newList.filter((item) => parseInt(item.status) == parseInt(ctx.rootState.s.status));
            }

            if (ctx.rootState.s && newList.length != ctx.state.orders.length) {
                ctx.rootState.s.clear = true;
            }

            ctx.dispatch("setSettings", ctx.rootState.s);
            ctx.commit("setOrdersF", newList);
        },
    },

    getters: {
        clusters(state) {
            return state.clustersF;
        },
        cluster(state) {
            return state.cluster;
        },
        store(state) {
            return state.store;
        },
        products(state) {
            return state.productsF;
        },
        product(state) {
            return state.product;
        },
        orders(state) {
            return state.ordersF;
        },
        order(state) {
            return state.order;
        },
    },
};
