<template>
    <div class="flex justify-between items-center gap-4 mt-8 mb-2">
        <h2 class="flex items-center gap-2 text-slate-500 text-2xl uppercase">
            ТОВАРЫ <span class="count">{{ products.length }}</span>
        </h2>

        <input type="text" class="input text-sm" placeholder="Поиск" v-model="s.findme" @input="filterOrders()" />

        <div v-if="s.clear" @click="clear()" class="w-20 text-white bg-gray-400 hover:bg-gray-300 text-sm text-center p-1 rounded cursor-pointer">
            Сброс
        </div>
    </div>

    <!-- {{ store }} -->

    <div class="grid grid-cols-9 text-slate-400 text-xs uppercase mb-px p-2">
        <div>ID</div>
        <div class="text-center">Москва и МО</div>
        <div class="text-center">Центр</div>
        <div class="text-center">Северо-Запад</div>
        <div class="text-center">Поволжье</div>
        <div class="text-center">Юг</div>
        <div class="text-center">Урал</div>
        <div class="text-center">Сибирь</div>
        <div class="text-center">Дальний восток</div>
    </div>

    <div v-for="item in store" :key="item" class="grid grid-cols-9 bg-white hover:bg-sky-100 hover:text-sky-600 text-sm rounded mb-px p-2">
        <div>{{ item.offer_id }}</div>
        <div class="text-center">{{ item.cluster1 }}</div>
        <div class="text-center">{{ item.cluster2 }}</div>
        <div class="text-center">{{ item.cluster3 }}</div>
        <div class="text-center">{{ item.cluster4 }}</div>
        <div class="text-center">{{ item.cluster5 }}</div>
        <div class="text-center">{{ item.cluster6 }}</div>
        <div class="text-center">{{ item.cluster7 }}</div>
        <div class="text-center">{{ item.cluster8 }}</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "ProductsPage",

    data() {
        return {
            moment: moment,
            clusters: {
                1: "Москва и МО",
                2: "Центр",
                3: "Северо-Запад",
                4: "Поволжье",
                5: "Юг",
                6: "Урал",
                7: "Сибирь",
            },

            statuses: {
                1: "упаковка",
                2: "отгрузка",
                3: "доставляется",
                4: "доставлено",
                5: "отменено",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "store", "products"]),

        result() {
            if (!this.clusters || !this.products) return [];

            let data = [];

            this.products.forEach((order) => {
                if (!data[order.id]) data[order.id] = {};

                // let cluster = this.clusters.filter((item) => item.region == order.region);
                // order.cluster = cluster[0].claster;
                data.push(order);
            });

            return data;
        },

        resultOrders() {
            if (!this.clusters || !this.products) return [];

            let data = [];

            this.products.forEach((order) => {
                let cluster = this.clusters.filter((item) => item.region == order.region);
                order.cluster = cluster[0].claster;
                data.push(order);
            });

            return data;
        },

        sumStatus() {
            if (!this.products) return [];

            let statuses = { 1: { qty: 0, sum: 0 }, 2: { qty: 0, sum: 0 }, 3: { qty: 0, sum: 0 }, 4: { qty: 0, sum: 0 }, 5: { qty: 0, sum: 0 } };

            this.products.forEach((order) => {
                statuses[order.status].qty += parseFloat(order.quantity);
                statuses[order.status].sum += parseFloat(order.price);
            });

            return statuses;
        },
    },

    methods: {
        ...mapActions(["getProducts", "filterOrders", "setSettings", "clearSettings", "fillOrders"]),

        clear() {
            this.clearSettings();
            this.fillOrders();
        },
    },

    mounted() {
        this.getProducts();
    },
};
</script>
