import { defineStore } from "pinia";
import API from "../services/api";
import { useMainStore as mainStore } from "./main";

export const useProductStore = defineStore("product", {
    state: () => ({
        productsAll: [],
        products: [],
        product: {},
    }),

    actions: {
        async getProducts() {
            mainStore().request(true);

            const res = await API.GET("product/product/all");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.productsAll = res.data.products;
            this.products = res.data.products;
        },

        pickProduct(product) {
            this.product = product;
        },

        filterProducts(find) {
            if (!this.productsAll) return null;

            let newList = this.productsAll;

            if (find !== undefined) {
                newList = newList.filter((item) => {
                    let offer_id = null;
                    let name = null;

                    if (item.offer_id) offer_id = item.offer_id.toString().toLowerCase().indexOf(find.toLowerCase()) !== -1;
                    if (item.name) name = item.name.toString().toLowerCase().indexOf(find.toLowerCase()) !== -1;
                    if (offer_id || name) return true;

                    return false;
                });
            }

            this.products = newList;
        },
    },
});
