<template>
    <div class="flex justify-between items-center gap-4 mt-4 mb-2">
        <h2 class="flex items-center gap-2 text-slate-500 text-2xl uppercase">
            ФИНАНСЫ <span class="count">{{ daily.length }}</span>
        </h2>
    </div>

    <div v-for="day in daily" :key="day" class="box pt-4 pb-2 gradient text-white mb-2">
        <div class="w-full grid grid-cols-6">
            <div class="flex flex-col">
                <span class="text-xs uppercase opacity-25 group-hover:opacity-100 duration-300">Дата</span>
                <span class="text-2xl">{{ moment(day.date).format("DD.MM.YYYY") }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs uppercase text-right opacity-25 group-hover:opacity-100 duration-300">ЗАКУП</span>
                <span class="text-2xl text-right">{{ Math.round(day.buy_sum).toLocaleString() }}₽</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs uppercase text-right opacity-25 group-hover:opacity-100 duration-300">КОЛ-ВО</span>
                <span class="text-2xl text-right">{{ day.buy_count }}₽</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs uppercase text-right opacity-25 group-hover:opacity-100 duration-300">Расход общий</span>
                <span class="text-2xl text-right">{{ Math.round(day.expend_per_day).toLocaleString() }}₽</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs uppercase text-right opacity-25 group-hover:opacity-100 duration-300">Выручка</span>
                <span class="text-2xl text-right">{{ Math.round(day.sell_sum).toLocaleString() }}₽</span>
            </div>

            <div class="flex flex-col">
                <span class="text-xs uppercase text-right opacity-25 group-hover:opacity-100 duration-300">Прибыль</span>
                <span class="text-2xl text-right" :class="{ '!text-teal-500': day.profit > 0, '!text-pink-500': day.profit < 0 }"
                    >{{ Math.round(day.profit).toLocaleString() }}₽</span
                >
            </div>
        </div>

        <!-- <span class="w-full text-md text-left text-slate-400 mt-4">ЗАКУПКИ</span>
        <div v-for="product in day.products" :key="product" class="w-full grid grid-cols-9">
            <div class="col-span-2">{{ product.offer_id }}</div>

            <div>{{ parseFloat(product.price).toFixed(2) }}</div>
            <div>{{ parseFloat(product.delivery).toFixed(2) }}</div>
            <div>{{ parseFloat(product.one_cost).toFixed(2) }}</div>

            <div>{{ product.qty }}</div>

            <div>{{ parseFloat(product.total).toFixed(2) }}</div>
        </div>

        <span class="w-full text-md text-left text-slate-400 mt-4">ПРОДАЖИ</span>
        <div v-for="sell in day.sells" :key="sell" class="w-full grid grid-cols-9">
            <div class="col-span-2">{{ sell.offer_id }}</div>

            <div>{{ parseFloat(sell.buy_price).toFixed(2) }}</div>
            <div>{{ parseFloat(sell.delivery).toFixed(2) }}</div>
            <div>{{ parseFloat(sell.cumulative_up).toFixed(2) }}</div>
            <div>{{ parseFloat(sell.buy_price + sell.delivery + sell.cumulative_up).toFixed(2) }}</div>

            <div>{{ parseFloat(sell.sell_price).toFixed(2) }}</div>

            <div>{{ sell.qty }}</div>

            <div>{{ parseFloat(sell.profit).toFixed(2) }}</div>
        </div> -->
    </div>

    <div class="w-full flex gap-4">
        <div class="w-52 py-1 px-2 shrink-0 border border-slate-100 rounded overflow-hidden text-sm">
            <div class="box gradient text-white">
                <div class="flex items-center !h-8 text-slate-500 !text-[16px]">ДАТА</div>
                <div class="font-bold bg-cyan-100/50 text-cyan-700">Бюджет c НДС</div>
                <div class="bb">Просмотры</div>
                <div class="bb">Клики</div>
                <div class="bb">Рег с рекламы</div>
                <div class="bb">РЕГИСТРАЦИЙ ВСЕГО</div>
                <div class="bb">Рег. (10:00 - 10:00)</div>
                <div v-if="!s.compact" class="bb text-slate-400">Конверсия посадки</div>
                <div v-if="!s.compact" class="text-slate-400">Цена реги CPA</div>
            </div>
        </div>

        <div v-if="daily" class="w-[calc(100vw-208px)] flex gap-2 overflow-x-scroll snap-x scroll-pl-2 scroll-smooth pl-2">
            <div
                v-for="day in daily"
                :key="day"
                class="box gradient text-white snap-start w-52 shrink-0 border border-slate-100 rounded overflow-hidden text-sm text-right"
                :class="{
                    'border !border-pink-300': [6, 7].includes(moment(day.date).isoWeekday()),
                    'border !border-teal-300': moment(day.date).format('Y-M-D') == moment().format('Y-M-D'),
                    'border !border-slate-300 ': !day.date && day.name,
                }"
            >
                <div class="w-full grid grid-cols-6">
                    <div>{{ day.date }}</div>
                    <div>{{ day.buy_sum }}</div>
                    <div>{{ day.buy_count }}</div>
                    <div>{{ day.expend_per_day }}</div>
                    <div>{{ day.profit }}</div>
                </div>

                <div v-for="product in day.products" :key="product" class="w-full grid grid-cols-9">
                    <div class="col-span-2">{{ product.offer_id }}</div>

                    <div>{{ parseFloat(product.price).toFixed(2) }}</div>
                    <div>{{ parseFloat(product.delivery).toFixed(2) }}</div>
                    <div>{{ parseFloat(product.one_cost).toFixed(2) }}</div>

                    <div>{{ product.qty }}</div>

                    <div>{{ product.total }}</div>
                </div>

                <div v-for="sell in day.sells" :key="sell" class="w-full grid grid-cols-9">
                    <div class="col-span-2">{{ sell.offer_id }}</div>

                    <div>{{ parseFloat(sell.buy_price).toFixed(2) }}</div>
                    <div>{{ parseFloat(sell.delivery).toFixed(2) }}</div>
                    <div>{{ parseFloat(sell.cumulative_up).toFixed(2) }}</div>
                    <div>{{ parseFloat(sell.buy_price + sell.delivery + sell.cumulative_up).toFixed(2) }}</div>

                    <div>{{ parseFloat(sell.sell_price).toFixed(2) }}</div>

                    <div>{{ sell.qty }}</div>

                    <div>{{ sell.profit }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="box gradient text-white">{{ daily }}</div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useFinanceStore } from "@/store/finance";

import moment from "moment";

export default {
    name: "FinancePage",

    data() {
        return {
            moment: moment,
            statuses: {
                1: "Создана",
                2: "План",
                3: "Оплачена",
                4: "Отправлена",
                5: "Получена",
            },
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "monthShort", "setPopup", "closePopup", "setSettings", "setMonth", "setPeriod", "setView"]),
        ...mapState(useFinanceStore, ["daily", "getDailyFinance"]),
    },

    methods: {},

    mounted() {
        this.getDailyFinance();
    },
};
</script>
