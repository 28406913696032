<template>
    <div class="flex justify-between items-center gap-4 mt-8 mb-2">
        <h2 class="flex items-center gap-2 text-slate-500 text-2xl uppercase">
            ОСТАТКИ <span class="count">{{ stocks.length }}</span>
        </h2>

        <input type="text" class="input text-sm" placeholder="Поиск" v-model="s.findme" @input="filterStocks()" />

        <select v-model="s.category_id" class="bg-white h-9 px-3 py-2 rounded text-sm text-sky-800" @change="filterStocks()">
            <template v-for="category in categories" :key="category.id">
                <option v-if="s.category_id == category.id" selected :value="category.id">{{ category.name }}</option>
                <option v-else :value="category.id">{{ category.name }}</option>
            </template>
        </select>

        <div v-if="s.clear" @click="clear()" class="w-20 text-white bg-gray-400 hover:bg-gray-300 text-sm text-center p-1 rounded cursor-pointer">
            Сброс
        </div>
    </div>

    <div class="grid grid-cols-15 gap-4 p-1 w-full text-white/50 text-xs uppercase">
        <div></div>
        <div class="col-span-3">НАЗВАНИЕ</div>
        <div class="col-span-2 text-right">ТИП</div>
        <div class="text-center">Москва и МО</div>
        <div class="text-center">Центр</div>
        <div class="text-center">Северо-Запад</div>
        <div class="text-center">Поволжье</div>
        <div class="text-center">Дон</div>
        <div class="text-center">Юг</div>
        <div class="text-center">Урал</div>
        <div class="text-center">Сибирь</div>
        <div class="text-center">Дальний восток</div>
    </div>

    <div class="max-h-[calc(100vh-250px)] overflow-y-auto">
        <div v-for="stock in stocks" :key="stock" class="grid grid-cols-15 items-center w-full hover:bg-white/10 border-b border-white/10">
            <img class="w-16 h-16 rounded p-1" :src="stock.image" :alt="stock.name" />

            <div class="flex flex-col col-span-3">
                <div class="text-sm text-white/25">{{ stock.offer_id }}</div>
                <template v-for="category in categories" :key="category">
                    <div class="text-sm text-white col-span-2" v-if="category.id == stock.category_id">{{ category.name }}</div>
                </template>
                <div class="text-xs text-white/25">{{ stock.name }}</div>
            </div>

            <div class="col-span-2 flex flex-col w-full text-slate-500 text-right text-sm border-r border-white/10 px-4">
                <div class="h-6">Остаток</div>
                <div class="h-6">В пути</div>
                <div class="h-6">Продаж 1 мес</div>
                <div class="h-6">Продаж 3 мес</div>
                <div class="h-6">К поставке</div>
            </div>

            <div class="flex flex-col w-full font-bold text-white text-center border-r border-white/10 px-4">
                <div :class="{ '!text-white/20': stock.cluster1.free == 0 }">{{ stock.cluster1.free }}</div>
                <div :class="{ '!text-white/20': stock.cluster1.promised == 0 }">{{ stock.cluster1.promised }}</div>
                <div :class="{ '!text-white/20': stock.cluster1.sell1 == 0 }">{{ stock.cluster1.sell1 }}</div>
                <div :class="{ '!text-white/20': stock.cluster1.sell3 == 0 }">{{ stock.cluster1.sell3 }}</div>
                <div
                    class="text-teal-400"
                    :class="{ '!text-white/20': stock.cluster1.sell3 - (stock.cluster1.free + stock.cluster1.promised) == 0 }"
                    v-if="stock.cluster1.free + stock.cluster1.promised <= stock.cluster1.sell1 / 2"
                >
                    {{ stock.cluster1.sell3 - (stock.cluster1.free + stock.cluster1.promised) }}
                </div>
                <div class="text-white/20" v-else>0</div>
            </div>

            <div class="flex flex-col w-full font-bold text-white text-center border-r border-white/10 px-4">
                <div :class="{ '!text-white/20': stock.cluster2.free == 0 }">{{ stock.cluster2.free }}</div>
                <div :class="{ '!text-white/20': stock.cluster2.promised == 0 }">{{ stock.cluster2.promised }}</div>
                <div :class="{ '!text-white/20': stock.cluster2.sell1 == 0 }">{{ stock.cluster2.sell1 }}</div>
                <div :class="{ '!text-white/20': stock.cluster2.sell3 == 0 }">{{ stock.cluster2.sell3 }}</div>
                <div
                    class="text-teal-400"
                    :class="{ '!text-white/20': stock.cluster2.sell3 - (stock.cluster2.free + stock.cluster2.promised) == 0 }"
                    v-if="stock.cluster2.free + stock.cluster2.promised <= stock.cluster2.sell1 / 2"
                >
                    {{ stock.cluster2.sell3 - (stock.cluster2.free + stock.cluster2.promised) }}
                </div>
                <div class="text-white/20" v-else>0</div>
            </div>

            <div class="flex flex-col w-full font-bold text-white text-center border-r border-white/10 px-4">
                <div :class="{ '!text-white/20': stock.cluster3.free == 0 }">{{ stock.cluster3.free }}</div>
                <div :class="{ '!text-white/20': stock.cluster3.promised == 0 }">{{ stock.cluster3.promised }}</div>
                <div :class="{ '!text-white/20': stock.cluster3.sell1 == 0 }">{{ stock.cluster3.sell1 }}</div>
                <div :class="{ '!text-white/20': stock.cluster3.sell3 == 0 }">{{ stock.cluster3.sell3 }}</div>
                <div
                    class="text-teal-400"
                    :class="{ '!text-white/20': stock.cluster3.sell3 - (stock.cluster3.free + stock.cluster3.promised) == 0 }"
                    v-if="stock.cluster3.free + stock.cluster3.promised <= stock.cluster3.sell1 / 2"
                >
                    {{ stock.cluster3.sell3 - (stock.cluster3.free + stock.cluster3.promised) }}
                </div>
                <div class="text-white/20" v-else>0</div>
            </div>

            <div class="flex flex-col w-full font-bold text-white text-center border-r border-white/10 px-4">
                <div :class="{ '!text-white/20': stock.cluster4.free == 0 }">{{ stock.cluster4.free }}</div>
                <div :class="{ '!text-white/20': stock.cluster4.promised == 0 }">{{ stock.cluster4.promised }}</div>
                <div :class="{ '!text-white/20': stock.cluster4.sell1 == 0 }">{{ stock.cluster4.sell1 }}</div>
                <div :class="{ '!text-white/20': stock.cluster4.sell3 == 0 }">{{ stock.cluster4.sell3 }}</div>
                <div
                    class="text-teal-400"
                    :class="{ '!text-white/20': stock.cluster4.sell3 - (stock.cluster4.free + stock.cluster4.promised) == 0 }"
                    v-if="stock.cluster4.free + stock.cluster4.promised <= stock.cluster4.sell1 / 2"
                >
                    {{ stock.cluster4.sell3 - (stock.cluster4.free + stock.cluster4.promised) }}
                </div>
                <div class="text-white/20" v-else>0</div>
            </div>

            <div class="flex flex-col w-full font-bold text-white text-center border-r border-white/10 px-4">
                <div :class="{ '!text-white/20': stock.cluster9.free == 0 }">{{ stock.cluster9.free }}</div>
                <div :class="{ '!text-white/20': stock.cluster9.promised == 0 }">{{ stock.cluster9.promised }}</div>
                <div :class="{ '!text-white/20': stock.cluster9.sell1 == 0 }">{{ stock.cluster9.sell1 }}</div>
                <div :class="{ '!text-white/20': stock.cluster9.sell3 == 0 }">{{ stock.cluster9.sell3 }}</div>
                <div
                    class="text-teal-400"
                    :class="{ '!text-white/20': stock.cluster9.sell3 - (stock.cluster9.free + stock.cluster9.promised) == 0 }"
                    v-if="stock.cluster9.free + stock.cluster9.promised <= stock.cluster9.sell1 / 2"
                >
                    {{ stock.cluster9.sell3 - (stock.cluster9.free + stock.cluster9.promised) }}
                </div>
                <div class="text-white/20" v-else>0</div>
            </div>

            <div class="flex flex-col w-full font-bold text-white text-center border-r border-white/10 px-4">
                <div :class="{ '!text-white/20': stock.cluster5.free == 0 }">{{ stock.cluster5.free }}</div>
                <div :class="{ '!text-white/20': stock.cluster5.promised == 0 }">{{ stock.cluster5.promised }}</div>
                <div :class="{ '!text-white/20': stock.cluster5.sell1 == 0 }">{{ stock.cluster5.sell1 }}</div>
                <div :class="{ '!text-white/20': stock.cluster5.sell3 == 0 }">{{ stock.cluster5.sell3 }}</div>
                <div
                    class="text-teal-400"
                    :class="{ '!text-white/20': stock.cluster5.sell3 - (stock.cluster5.free + stock.cluster5.promised) == 0 }"
                    v-if="stock.cluster5.free + stock.cluster5.promised <= stock.cluster5.sell1 / 2"
                >
                    {{ stock.cluster5.sell3 - (stock.cluster5.free + stock.cluster5.promised) }}
                </div>
                <div class="text-white/20" v-else>0</div>
            </div>

            <div class="flex flex-col w-full font-bold text-white text-center border-r border-white/10 px-4">
                <div :class="{ '!text-white/20': stock.cluster6.free == 0 }">{{ stock.cluster6.free }}</div>
                <div :class="{ '!text-white/20': stock.cluster6.promised == 0 }">{{ stock.cluster6.promised }}</div>
                <div :class="{ '!text-white/20': stock.cluster6.sell1 == 0 }">{{ stock.cluster6.sell1 }}</div>
                <div :class="{ '!text-white/20': stock.cluster6.sell3 == 0 }">{{ stock.cluster6.sell3 }}</div>
                <div
                    class="text-teal-400"
                    :class="{ '!text-white/20': stock.cluster6.sell3 - (stock.cluster6.free + stock.cluster6.promised) == 0 }"
                    v-if="stock.cluster6.free + stock.cluster6.promised <= stock.cluster6.sell1 / 2"
                >
                    {{ stock.cluster6.sell3 - (stock.cluster6.free + stock.cluster6.promised) }}
                </div>
                <div class="text-white/20" v-else>0</div>
            </div>

            <div class="flex flex-col w-full font-bold text-white text-center border-r border-white/10 px-4">
                <div :class="{ '!text-white/20': stock.cluster7.free == 0 }">{{ stock.cluster7.free }}</div>
                <div :class="{ '!text-white/20': stock.cluster7.promised == 0 }">{{ stock.cluster7.promised }}</div>
                <div :class="{ '!text-white/20': stock.cluster7.sell1 == 0 }">{{ stock.cluster7.sell1 }}</div>
                <div :class="{ '!text-white/20': stock.cluster7.sell3 == 0 }">{{ stock.cluster7.sell3 }}</div>
                <div
                    class="text-teal-400"
                    :class="{ '!text-white/20': stock.cluster7.sell3 - (stock.cluster7.free + stock.cluster7.promised) == 0 }"
                    v-if="stock.cluster7.free + stock.cluster7.promised <= stock.cluster7.sell1 / 2"
                >
                    {{ stock.cluster7.sell3 - (stock.cluster7.free + stock.cluster7.promised) }}
                </div>
                <div class="text-white/20" v-else>0</div>
            </div>

            <div class="flex flex-col w-full font-bold text-white text-center border-r border-white/10 px-4">
                <div :class="{ '!text-white/20': stock.cluster8.free == 0 }">{{ stock.cluster8.free }}</div>
                <div :class="{ '!text-white/20': stock.cluster8.promised == 0 }">{{ stock.cluster8.promised }}</div>
                <div :class="{ '!text-white/20': stock.cluster8.sell1 == 0 }">{{ stock.cluster8.sell1 }}</div>
                <div :class="{ '!text-white/20': stock.cluster8.sell3 == 0 }">{{ stock.cluster8.sell3 }}</div>
                <div
                    class="text-teal-400"
                    :class="{ '!text-white/20': stock.cluster8.sell3 - (stock.cluster8.free + stock.cluster8.promised) == 0 }"
                    v-if="stock.cluster8.free + stock.cluster8.promised <= stock.cluster8.sell1 / 2"
                >
                    {{ stock.cluster8.sell3 - (stock.cluster8.free + stock.cluster8.promised) }}
                </div>
                <div class="text-white/20" v-else>0</div>
            </div>
        </div>
    </div>

    <!-- <div class="flex gap-10 mt-10">
        <div class="text-white">Остаток: 50</div>
        <div class="text-sky-700">В пути: 10</div>
        <div class="text-teal-400" :class="{ '!text-white/20stock.cluster5.sell3 - (stock.cluster5.free + stock.cluster5.promised).sell3 == 0 }">Продаж 1 мес: 40</div>
        <div class="text-teal-700">Продаж 3 мес: 90</div>
    </div> -->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useDashBoardStore } from "@/store/dashboard";

export default {
    name: "StocksPage",

    computed: {
        ...mapState(useMainStore, ["s", "closePopup", "clearSettings"]),
        ...mapState(useDashBoardStore, ["stocks", "categories", "filterStocks"]),
    },

    methods: {
        clear() {
            this.clearSettings();
            this.filterStocks();
        },
    },
};
</script>
