<template>
    <div class="flex justify-between items-center gap-4 mt-8 mb-2">
        <h2 class="flex items-center gap-2 text-slate-500 text-2xl uppercase">
            PNL <span class="count">{{ pnl.length }}</span>
        </h2>

        <input type="text" class="input text-sm" placeholder="Поиск" v-model="s.findme" @input="filterStocks()" />

        <!-- <select v-model="s.category_id" class="bg-white h-9 px-3 py-2 rounded text-sm text-sky-800" @change="filterStocks()">
            <template v-for="category in categories" :key="category.id">
                <option v-if="s.category_id == category.id" selected :value="category.id">{{ category.name }}</option>
                <option v-else :value="category.id">{{ category.name }}</option>
            </template>
        </select> -->

        <div v-if="s.clear" @click="clear()" class="w-20 text-white bg-gray-400 hover:bg-gray-300 text-sm text-center p-1 rounded cursor-pointer">
            Сброс
        </div>
    </div>

    <div class="flex">
        <div class="tbl w-52 shrink-0 border border-slate-100 rounded overflow-hidden text-sm">
            <div class="flex justify-center items-center text-lg bg-white h-8 mb-1">КАТЕГОРИИ</div>

            <div v-for="category in table[0].cats" :key="category" class="bg-white gap-2 h-24 leading-6 overflow-hidden mb-1">
                <div>{{ category.name }}</div>
                <div>{{ category.revenue }}</div>
            </div>
        </div>

        <div class="w-[calc(100vw-208px)] flex gap-2 overflow-x-scroll snap-x scroll-pl-2 scroll-smooth pl-2">
            <template v-for="item in table" :key="item">
                <template v-if="item.date != 'titles'">
                    <div class="snap-start w-32 shrink-0 border border-slate-100 rounded overflow-hidden text-sm text-right">
                        <div class="flex justify-center items-center text-lg bg-white h-8 mb-1">{{ item.date }}</div>

                        <div v-for="category in item.cats" :key="category" class="bg-white gap-2 h-24 leading-6 overflow-hidden mb-1">
                            <div>{{ parseInt(category?.revenue) }}</div>
                            <div>
                                {{ parseInt(category?.commission) }} ({{ parseFloat((category?.commission * -100) / category?.revenue).toFixed(2) }})
                            </div>
                            <div>
                                {{ parseInt(category?.delivery) }} ({{ parseFloat((category?.delivery * -100) / category?.revenue).toFixed(2) }})
                            </div>
                            <div>{{ parseInt(category?.refund) }} ({{ parseFloat((category?.refund * -100) / category?.revenue).toFixed(2) }})</div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>

    <!-- <div class="tbl w-52 shrink-0 border border-slate-100 rounded overflow-hidden text-sm">
            <div v-for="category in pnl.title" :key="category.category_id" class="bg-white h-24 px-2 overflow-hidden mb-1">
                <div>{{ category.category_id }}</div>
                <div>{{ category.name }}</div>
                <div>{{ parseInt(category.revenue).toLocaleString() }}</div>
            </div>
        </div> -->

    <!-- <div v-if="pnl" class="w-[calc(100vw-208px)] flex gap-2 overflow-x-scroll snap-x scroll-pl-2 scroll-smooth pl-2">
            <div
                v-for="item in pnl"
                :key="item.category_id"
                class="snap-start w-32 shrink-0 border border-slate-100 rounded overflow-hidden text-sm text-right"
            >
                <div class="bg-white mb-1">
                    {{ item }}
                </div> -->
    <!-- <template v-if="key != 'title'">
                    <div v-for="category in month" :key="category.category_id" class="bg-white gap-2 h-24 leading-6 overflow-hidden mb-1">
                        <div>{{ parseInt(category.category_id) }}</div>
                        <div>{{ parseInt(category.revenue) }}</div>
                        <div>{{ parseInt(category.commission) }} ({{ parseFloat((category.commission * -100) / category.revenue).toFixed(2) }})</div>
                        <div>
                            {{ parseInt(category.delivery) }} ({{ parseFloat((category.delivery * -100) / category.revenue).toFixed(2) }})
                        </div>
                        <div>
                            {{ parseInt(category.refund) }} ({{ parseFloat((category.refund * -100) / category.revenue).toFixed(2) }})
                        </div>
                    </div>
                </template> -->
    <!-- </div>
        </div>-->
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useDashBoardStore } from "@/store/dashboard";

import moment from "moment";

export default {
    name: "PnlPage",

    data() {
        return { moment: moment };
    },

    computed: {
        ...mapState(useMainStore, ["s", "closePopup", "clearSettings"]),
        ...mapState(useDashBoardStore, ["pnl", "categories", "getPnl"]),

        table() {
            if (!this.pnl) return [];

            let table = [];

            let column = { date: "titles", cats: {} };
            this.pnl.forEach((item) => {
                if (!column.cats[item.id]) column.cats[item.id] = { name: item.name, revenue: item.revenue };
            });

            table.push(column);

            let srt = moment("2022-01-01").format("YYYY-MM-01");
            let end = moment().format("YYYY-MM-01");

            while (srt <= end) {
                let column = { date: srt, cats: {} };

                this.pnl.forEach((item) => {
                    if (!column.cats[item.id]) column.cats[item.id] = { revenue: 0, commission: 0, delivery: 0, refund: 0 };

                    if (item?.dates[srt]) column.cats[item.id] = item.dates[srt];
                });

                table.push(column);
                srt = moment(srt).add(1, "month").format("YYYY-MM-01");
            }

            return table;
        },
    },

    mounted() {
        this.getPnl();
    },
};
</script>
