<template>
    <div class="w-3/4 fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] box gradient text-white/75 overflow-hidden z-50 shadow-lg">
        <span class="text-xs uppercase">КАТЕГОРИИ</span>
        <div class="grid grid-cols-9 gap-4 p-2 border-b border-white/10 w-full hover:bg-white/10 hover:text-white">
            <div class="col-span-3">КАТЕГОРИЯ</div>
            <div>ОБОРОТ</div>
            <div>КОЛ-ВО</div>
            <div class="text-right">СРЕДНИЙ ЧЕК</div>
            <div class="text-right">КОМИССИЯ</div>
            <div class="text-right">ДОСТАВКА</div>
            <div class="text-right">ВОЗВРАТЫ</div>
        </div>

        <div
            v-for="cat in salesbycategory"
            :key="cat"
            class="grid grid-cols-9 gap-4 p-2 border-b border-white/10 w-full hover:bg-white/10 hover:text-white"
        >
            <template v-for="category in categories" :key="category">
                <div class="col-span-3" v-if="category.id == cat.id">{{ category.name }}</div>
            </template>
            <div class="text-right tabular-nums">{{ parseInt(cat.revenue).toLocaleString() }} ₽</div>
            <div class="text-right tabular-nums">{{ cat.solditems }}</div>
            <div class="text-right tabular-nums">{{ parseInt(cat.revenue / cat.solditems).toLocaleString() }} ₽</div>

            <div class="text-right tabular-nums">{{ parseInt(cat.com).toLocaleString() }} ₽</div>
            <div class="text-right tabular-nums">{{ parseInt(cat.dev).toLocaleString() }} ₽</div>
            <div class="text-right tabular-nums">{{ parseInt(cat.ref).toLocaleString() }} ₽</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useDashBoardStore } from "@/store/dashboard";

export default {
    name: "SalesByCategory",

    props: {
        salesbycategory: Array,
    },

    computed: {
        ...mapState(useMainStore, ["s", "closePopup", "clearSettings"]),
        ...mapState(useDashBoardStore, ["categories"]),
    },
};
</script>
