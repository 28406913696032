<template>
    <div class="flex justify-between items-center w-full h-16 py-4 px-4 gradient backdrop-blur">
        <ul class="menu flex justify-between items-center text-center text-sm h-12 gap-4">
            <li :class="{ activeMenu: s.activeMenu == item.url }" v-for="item in menu" :key="item">
                <a @click="$router.push(item.url), setActiveMemu(item.url)">
                    {{ item.name }}
                </a>
            </li>
        </ul>

        <!-- <ul class="menu flex justify-between items-center text-center text-sm h-12 gap-4">
            <li :class="{ activeMenu: s.activeMenu == '/dashboard' }">
                <a @click="$router.push('/dashboard'), setActiveMemu('/dashboard')">Дашборд</a>
            </li> -->
        <!--            <li :class="{ activeMenu: s.activeMenu == '/products' }">-->
        <!--                <a @click="$router.push('/products'), setActiveMemu('/products')">Товары</a>-->
        <!--            </li>-->
        <!--            <li :class="{ activeMenu: s.activeMenu == '/orders' }">-->
        <!--                <a @click="$router.push('/orders'), setActiveMemu('/orders')">Заказы</a>-->
        <!--            </li>-->
        <!-- </ul> -->

        <div class="flex items-center gap-4 h-12 font-medium text-sm text-slate-500">
            {{ profile?.name }}
            <a
                class="bg-white w-8 h-8 my-2 rounded-full overflow-hidden relative cursor-pointer"
                @click="$router.push('/profile'), setActiveMemu('/profile')"
            >
                <img v-if="profile?.picture" class="w-full h-full object-cover" :src="'https://api.ulangroup.ru/users/mini/' + profile?.picture" />
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useProfileStore } from "@/store/profile";

export default {
    name: "TopMenu",

    data() {
        return {};
    },

    computed: {
        ...mapState(useMainStore, ["s", "setActiveMemu"]),
        ...mapState(useProfileStore, ["profile", "menu", "getProfile"]),
    },
};
</script>
