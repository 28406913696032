<template>
    <div
        class="w-3/4 fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] box gradient text-white/75 overflow-hidden z-50 shadow-lg pb-20"
    >
        <div class="w-full flex flex-col mb-4">
            <label class="text-xs text-left text-slate-400 mb-1">Товары</label>
            <input type="text" class="input" @input="filterProducts(find_product)" v-model="find_product" />
        </div>

        <div class="h-96 overflow-y-scroll">
            <div
                v-for="product in products"
                :key="product"
                class="grid grid-cols-10 items-center w-full hover:bg-white/10 border-b border-white/10 text-sm"
            >
                <img class="w-12 h-12 rounded p-1" :src="product.image" :alt="product.name" />

                <div class="col-span-2">{{ product.offer_id }}</div>

                <div class="col-span-6 flex-wrap">{{ product.name }}</div>

                <div v-if="yet?.includes(product.id)" class="flex justify-end pr-2 text-teal-400" @click="removeFromBuying(product)">
                    <IconOk />
                </div>
                <div v-else class="flex justify-end pr-2" @click="addProductToBuying(product.id)"><IconPlus :size="5" /></div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="closePopup()">Закрыть</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useSkladStore } from "@/store/sklad";
import { useProductStore } from "@/store/product";
import moment from "moment";

export default {
    name: "AddProductToBuying",

    data() {
        return {
            moment: moment,
            find_product: "",
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "closePopup"]),
        ...mapState(useSkladStore, ["createBuying", "setSupplier", "addProductToBuying", "removeFromBuying", "suppliers", "buying"]),
        ...mapState(useProductStore, ["getProducts", "filterProducts", "products"]),

        yet() {
            if (!this.buying || !this.buying.buyingProducts) return null;

            return this.buying.buyingProducts.map((obj) => obj.product_id);
        },
    },

    mounted() {
        this.getProducts();
    },
};
</script>
