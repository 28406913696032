<template>
    <div v-if="currentRoute != 'Login'" class="fixed z-50 w-full">
        <TopMenu />
    </div>

    <div
        class="min-h-screen bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-[rgb(9,29,70)] to-[rgb(0,10,20)] p-4 pt-20"
        :class="{ '!p-0': currentRoute == 'Login' }"
    >
        <RouterView />
    </div>

    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";

import TopMenu from "./components/TopMenu.vue";

export default {
    name: "App",

    components: { TopMenu },

    computed: {
        ...mapState(useMainStore, ["s", "alert", "routes", "loadData", "closePopup"]),

        currentRoute() {
            return this.$route.name;
        },
    },

    watch: {
        alert() {
            if (this.alert?.type == "success") {
                this.$toast.success(this.alert.msg);
            }
            if (this.alert?.type == "error") {
                this.$toast.error(this.alert.msg);
            }
            if (this.alert?.type == "warning") {
                this.$toast.warning(this.alert.msg);
            }
            if (this.alert?.type == "info") {
                this.$toast.info(this.alert.msg);
            }
        },
    },

    mounted() {
        this.$nextTick(function () {
            this.loadData();
        });
    },
};
</script>

<style>
.c-toast--success {
    background-color: #163364 !important;
}
</style>
