import { defineStore } from "pinia";
import API from "../services/api";
import axios from "axios";
import router from "@/services/router";
import { useMainStore as mainStore } from "./main";

export const useProfileStore = defineStore("profile", {
    state: () => ({
        menu: {},
        profile: {},
    }),

    actions: {
        async login(data) {
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("password", data.password);

            const res = await axios.post("https://api.ulangroup.ru/site/login", formData);

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            if (res.data.token) localStorage.setItem("AUTH", res.data.token);
            if (res.data.profile) this.setProfile(res.data.profile);
            if (res.data.menu) this.menu = res.data.menu;

            if (this.profile?.r == "Or") return router.push("/dashboard");
            // if (this.profile?.r == "Mn") return router.push("/dashboard");
            // return router.push("/dashboard");
        },

        // async registration(data) {
        //     const res = await axios.post("https://api.ulangroup.ru/site/registration", data);
        //     if (res.data.alert) mainStore().setAlert(res.data.alert);
        //     if (res.data && res.data.token) {
        //         this.profile = res.data.profile;
        //         this.menu = res.data.menu;
        //         if (!this.profile || !this.menu) router.push("/login");
        //         localStorage.setItem("AUTH", res.data.token);
        //         localStorage.setItem("profile", JSON.stringify(this.profile));
        //         localStorage.setItem("menu", JSON.stringify(this.menu));
        //         if (this.profile.r == "Mn") return router.push("/dashboard");
        //         router.push("/dashboard");
        //     }
        // },

        logout() {
            localStorage.removeItem("AUTH");
            localStorage.removeItem("profile");
            localStorage.removeItem("menu");
            this.profile = null;
            this.menu = null;
            router.push("/login");
        },

        setProfile(profile) {
            this.profile = profile;
            if (this.profile.picture) localStorage.setItem("picture", profile.picture);
            if (this.profile.username) localStorage.setItem("username", profile.username);
        },

        async getProfile() {
            if (this.profile?.id && this.menu) return;

            const res = await API.GET("user/user/profile");
            if (res.data && res.data.profile && res.data.menu) {
                this.setProfile(res.data.profile);
                this.menu = res.data.menu;
                if (this.profile && this.menu && router.currentRoute._value.name == "Login") router.push("/dashboard");
            } else {
                this.menu = null;
                this.profile = null;
                // return router.push("/login");
            }
        },

        async uploadAvatar(data) {
            console.log("uploadAvatar", data);
            let formData = new FormData();

            let file = data.e.target.files[0];

            let ext = "не определилось";
            let parts = file.name.split(".");
            if (parts.length > 1) ext = parts.pop();

            if (!["jpeg", "jpg", "png"].includes(ext)) {
                mainStore().setAlert({ msg: "Неправильный формат. Загрузите .jpg .png", type: "error" });
                return;
            }

            formData.append("picture", file);

            const res = await API.POST("user/user/upload-avatar", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    this.progressBar = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                },
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            if (res.data && res.data.profile) {
                this.profile = res.data.profile;
                if (this.profile.picture) localStorage.setItem("picture", this.profile.picture);
            }
        },

        async changeRole(role) {
            const formData = new FormData();
            formData.append("role", role);
            const res = await API.POST("user/user/role", formData);
            this.profile = res.data.profile;
            this.menu = res.data.menu;
        },

        getPermission(url) {
            if (!this.profile || !this.profile.r || !this.menu) router.push("/login");

            let permission = false;
            this.menu.forEach((item) => {
                if (item.url == url) permission = true;
            });

            if (!permission) router.push(this.menu[0].url);
        },
    },
});
