import { defineStore } from "pinia";
import API from "../services/api";
// import router from "@/services/router";

import { useMainStore as mainStore } from "./main";

export const useExpensesStore = defineStore("expenses", {
    state: () => ({
        expensesAll: [],
        expenses: [],
        expense: {},
    }),

    actions: {
        // loadExpense() {
        //     const buying = localStorage.getItem("buying");
        //     if (buying) this.buying = JSON.parse(buying);
        // },

        async getExpenses() {
            const res = await API.GET("finance/expense/all");
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.expensesAll = res.data.expenses;
            this.expenses = res.data.expenses;

            // this.loadSklad();
        },
    },
});
