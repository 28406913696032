import { useProfileStore as profileStore } from "@/store/profile";

const authService = {
    async isLogged() {
        console.log("auth isLogged", profileStore().profile?.id);

        if (profileStore().profile?.id && profileStore().menu && !!localStorage.getItem("AUTH")) return true;

        await profileStore().getProfile();

        if (profileStore().profile?.id && profileStore().menu && !!localStorage.getItem("AUTH")) return true;

        return false;
    },
};

export default authService;
